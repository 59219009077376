import React, { } from "react";
import { SimpleList, useTranslate } from 'react-admin';
import FormatDate from '../../../components/FormatDate';
import { SimpleListRowStyle } from '../../Shared/SimpleListRowStyle';

export const MobileListView = () => {
    const t = useTranslate()
    return (
        <SimpleList
            rowSx={SimpleListRowStyle}
            primaryText={record => record.publicEventDescription}
            secondaryText={record => FormatDate(record.bookingDate)}
            tertiaryText={record => `${t('switch.portal.enquiry.budget')}: £${record.budget}`}
            linkType={'show'}
        />
    );
}