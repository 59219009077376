export const SimpleListRowStyle = () => (
    {
        position: 'relative',
        overflow: 'hidden',
        padding: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 1,

        '& .icon': {
            color: 'secondary.main',
        },
        '&:before': {
            position: 'absolute',
            top: '50%',
            left: 0,
            display: 'block',
            content: `''`,
            height: '350%',
            aspectRatio: '1',
            transform: 'translate(-30%, -60%)',
            borderRadius: '50%',
            backgroundColor: 'secondary.main',
            opacity: 0.15,
        },
    }
)