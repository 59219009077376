import * as React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import { useGetOne, radiantDarkTheme, radiantLightTheme, useStore, useAuthProvider, usePermissions } from 'react-admin'
import { alpha } from '@mui/material';
import englishMessages from 'ra-language-english';
import english from "../Locale/en/translation.json";
import debug from "../Locale/debug/translation.json";
import { FontPicker } from '../Shared/FontPicker';

export const usePortal = () => {
    const context = usePortalContext()

    return context
}

export const PortalContext = createContext(undefined)

export const PortalContextProvider = ({ context, children, setThemes, setTranslations, loaded, setLoaded, setPermissions }) => {

    const auth = useAuthProvider()
    const [themeCache, setThemeCache] = useStore('portal.settings.theme', undefined);
    const [translationCache, setTranslationCache] = useStore('portal.settings.translation', undefined); 
    const { refetch, permissions } = usePermissions();
    const [settingsData, setSettingsData] = useState();

    const settings = useGetOne("settings", { id: 1 }, {
        onSuccess: (data) => {
            setSettingsData(data)
            setLoaded(true)
        },
        enabled: !loaded,
        staleTime: 24 * 60 * 60 * 1000
    });


    useEffect(() => {
        if (!loaded) return
        if (permissions) {
            setPermissions(permissions)
            return;
        }
        refetch()
    }, [loaded, permissions, children])

    useEffect(() => {
        if (loaded) return
        setThemes(themeCache)
        setTranslations(translationCache)
    }, [])

    useEffect(() => {
        if (!settingsData?.theme?.brandColour) return;

        const theme = settingsData?.theme;

        let light = {
            ...radiantLightTheme,
            typography: {
                fontFamily: FontPicker(theme?.font)
            },
            palette: {
                ...radiantLightTheme?.palette,
                background: { default: theme?.lightModeColours?.background, paper: theme?.lightModeColours?.paper },
                primary: {
                    main: theme?.lightModeColours?.primary,
                },
                secondary: {
                    main: theme?.lightModeColours?.secondary,
                },
                text: {
                    main: theme?.lightModeColours?.primaryText,
                    secondary: theme?.lightModeColours?.secondaryText,
                }

            }
        }
        let dark = {
            ...radiantDarkTheme,
            typography: {
                fontFamily: FontPicker(theme?.font)
            },
            palette: {
                ...radiantDarkTheme?.palette,
                background: { default: theme?.darkModeColours?.background, paper: theme?.darkModeColours?.paper },
                primary: {
                    main: theme?.darkModeColours?.primary,
                },
                secondary: {
                    main: theme?.darkModeColours?.secondary,
                },
                text: {
                    main: theme?.darkModeColours?.primaryText,
                    secondary: theme?.darkModeColours?.secondaryText,
                }
            }
        }

        light.components = componentsOverrides(light);
        dark.components = componentsOverrides(dark);
        setThemes({ light: light, dark: dark })
        setThemeCache({ light: light, dark: dark });
        auth.setPermissions(settingsData?.theme?.rights)

    }, [settingsData?.theme])


    useEffect(() => {
        if (!settings?.data?.translations) return;
        
        UpdateTranslations()


    }, [settingsData?.translations, permissions])

    function isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    function mergeDeep(target, source) {
        let output = Object.assign({}, target);
        if (isObject(target) && isObject(source)) {
            Object.keys(source).forEach(key => {
                if (isObject(source[key])) {
                    if (!(key in target))
                        Object.assign(output, { [key]: source[key] });
                    else
                        output[key] = mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(output, { [key]: source[key] });
                }
            });
        }
        return output;
    }

    function componentsOverrides(theme) {
        const shadows = [
            alpha(theme.palette.primary.main, 0.2),
            alpha(theme.palette.primary.main, 0.1),
            alpha(theme.palette.primary.main, 0.05),
        ];
        return {
            MuiAppBar: {
                styleOverrides: {
                    colorSecondary: {
                        backgroundColor: theme.palette.background.default,
                        color: theme.palette.text.primary,
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    variant: 'outlined',
                },
                styleOverrides: {
                    sizeSmall: {
                        padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
                    },
                },
            },
            MuiFormControl: {
                defaultProps: {
                    variant: 'outlined',
                    margin: 'dense',
                    size: 'small',
                },
            },
            MuiPaper: {
                styleOverrides: {
                    elevation1: {
                        boxShadow: `${shadows[0]} -2px 2px, ${shadows[1]} -4px 4px,${shadows[2]} -6px 6px`,
                    },
                    root: {
                        backgroundClip: 'padding-box',
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: theme.spacing(1.5),
                        '&.MuiTableCell-sizeSmall': {
                            padding: theme.spacing(1),
                        },
                        '&.MuiTableCell-paddingNone': {
                            padding: 0,
                        },
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        '&:last-child td': { border: 0 },
                    },
                },
            },
            MuiTextField: {
                defaultProps: {
                    variant: 'outlined',
                    margin: 'dense',
                    size: 'small',
                },
            },
            RaDatagrid: {
                styleOverrides: {
                    root: {
                        '& .RaDatagrid-headerCell': {
                            color: theme.palette.primary.main,
                        },
                    },
                },
            },
            RaFilterForm: {
                styleOverrides: {
                    root: {
                        [theme.breakpoints.up('sm')]: {
                            minHeight: theme.spacing(6),
                        },
                    },
                },
            },
            RaLayout: {
                styleOverrides: {
                    root: {
                        '& .RaLayout-appFrame': { marginTop: theme.spacing(5) },
                    },
                },
            },
            RaMenuItemLink: {
                styleOverrides: {
                    root: {
                        borderLeft: `3px solid ${theme.palette.primary.contrastText}`,
                        '&:hover': {
                            borderRadius: '0px 100px 100px 0px',
                        },
                        '&.RaMenuItemLink-active': {
                            borderLeft: `3px solid ${theme.palette.primary.main}`,
                            borderRadius: '0px 100px 100px 0px',
                            backgroundImage: `linear-gradient(98deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark} 94%)`,
                            boxShadow: theme.shadows[1],
                            color: theme.palette.primary.contrastText,

                            '& .MuiSvgIcon-root': {
                                fill: theme.palette.primary.contrastText,
                            },
                        },
                    },
                },
            },
        };
    };
    function UpdateTranslations() {

        const em = mergeDeep(english, settings?.data?.translations?.en)

        const e = {
            ...englishMessages,
            ...em
        };

        const dm = mergeDeep(debug, settings?.data?.translations?.debug)

        const d = {
            ...englishMessages,
            ...dm
        };

        const newTranslation = permissions?.showDebug ? { english: e, debug: d } : { english: e} 

        if (JSON.stringify(newTranslation) === JSON.stringify(translationCache)) return;
        setTranslations(newTranslation)
        setTranslationCache(newTranslation)
    }

    
    // State Variables

    // Use Effects

    // Conext Functions

    let contextData = {
        settings: settings?.data,
        isLoading: settings?.isLoading,
        theme: settings?.data?.theme,
        widgetKey: settings?.data?.portalSettings?.widgetKey,
        ...context,
    }

    //console.log(contextData)
    return (
        < PortalContext.Provider value={contextData} >
            {children}
        </PortalContext.Provider >
    )
}

export const usePortalContext = () => useContext(PortalContext)