import React, {} from "react";
import MyAccount from "./MyAccount";
import { Title, useTranslate, useAuthenticated } from "react-admin";
import { MyAccountContextProvider } from "../Contexts/useMyAccount";

const MyAccountParent = () => {
    const translate = useTranslate();
    useAuthenticated();

    return (
            <MyAccountContextProvider>
            <Title title={translate('switch.portal.myaccount.profile.title')} />
                <MyAccount />
            </MyAccountContextProvider>
    );
};

export default MyAccountParent;



