import * as React from 'react';
import { useTranslate, useRedirect, usePermissions } from 'react-admin';
import Button from '@mui/material/Button';
const NewBookingLink = (props) => {
    const { value } = props;
    const translate = useTranslate();
    const redirect = useRedirect();
    const { isLoading, permissions } = usePermissions();

    if (!permissions?.createNewBooking) return;

    if (!value) return;

    function goToWidget() {
        const path = "https://www.live-booking.co.uk/" + value;
        redirect(path)
    }

    return (
        <Button variant="outlined" onClick={goToWidget} >{translate('switch.portal.dashboard.newbooking')} </Button>
    );
};

export default NewBookingLink;