import React from 'react';
import {
    useMediaQuery
} from '@mui/material';
import { MobileShowView } from './ShowViews/MobileShowView';
import { DesktopShowView } from './ShowViews/DesktopShowView';

export const ShowEnquiry = () => {
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    )

    return (
        isSmall ? <MobileShowView/> : <DesktopShowView/>
    );
};