import React, { useState } from 'react'
import { Dialog, DialogContent } from '@mui/material';
import { usePayBooking } from '../Contexts/usePayBooking';
import { PaymentForm } from './PaymentForm';

export const PaymentDialog = () => {
    const { bookingPayDialogOpen, CloseBookingPayDialog } = usePayBooking()

    return (
        <Dialog open={bookingPayDialogOpen} onClose={CloseBookingPayDialog}>
            <DialogContent>
                <PaymentForm />
            </DialogContent>
        </Dialog>
    )
}