import * as React from 'react';
import { useTranslate, usePermissions } from 'react-admin';
import CardWithIcon from './CardWithIcon';
import TodayIcon from '@mui/icons-material/Today';

const CurrentEnquires = (props) => {
    const { value } = props;
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (!permissions?.showEnquiries) return;

    return (
        <CardWithIcon
            to="Enquiry"
            icon={TodayIcon}
            title={translate('switch.portal.dashboard.currentenquires')}
            subtitle={value}
        />
    );
};

export default CurrentEnquires;