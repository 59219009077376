import React, { } from "react";
import {
    useRecordContext,
    useTranslate,
    Datagrid,
    DateField,
    ListContextProvider
} from 'react-admin';
import { useRepeatBooking } from "../../Contexts/useRepeatBooking";
import { ExcludeRepeatBookingInstance } from "./ExcludeRepeatBookingInstance";


export const FutureDates = () => {
    const record = useRecordContext();
    if (!record) return null
    const t = useTranslate()
    const { heldDates, IsDateExcluded } = useRepeatBooking()


    const rowStyle = (record, index) => {
        if (IsDateExcluded(record.bookingDate)) {
            return { backgroundColor: 'grey', textDecoration: 'line-through' }
        }
        return
    }

    return (

        <ListContextProvider value={heldDates}>
            <Datagrid bulkActionButtons={false} rowStyle={rowStyle}>

                <DateField
                    source='bookingDate'
                    options={{
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    }}
                />
                <ExcludeRepeatBookingInstance />
            </Datagrid>
        </ListContextProvider>
    )
}