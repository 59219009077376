import React, { useState } from 'react'
import { Button, Box, Typography, DialogActions, Stack } from '@mui/material';
import { CurrencyFormat } from '../../guestPortalComponents/CurrencyFormat';
import { PaymentContextProvider } from '../../contexts/PaymentContext';
import { PayButton, CancelButton } from './PaymentActionButtons';
import PaymentProcessorSelector from './PaymentProcessorSelector';
import { useTranslate, useRecordContext, useDataProvider, Loading, useGetOne } from 'react-admin';
import { usePortal } from '../Contexts/usePortal';
import { usePayBooking } from '../Contexts/usePayBooking';


export const PaymentForm = () => {
    const translate = useTranslate()
    const record = useRecordContext()
    const { settings } = usePortal()
    const dataProvider = useDataProvider()
    const { CloseBookingPayDialog, showForm, ShowPaymentForm } = usePayBooking()

    const stripe = {
        publishableKey: settings?.portalSettings?.stripeKey,
        connectedAccountId: settings?.portalSettings?.stripeAccountId
    }


    const { data, isLoading } = useGetOne('Booking', { id: record?.bookingId }, { staleTime: 5 * 60 * 1000 });

    if (isLoading || !record) return <Loading />


    return (
        <PaymentContextProvider context={{ savedCards: settings?.savedCards, stripe: stripe, api: dataProvider, booking: data, amount: record?.amount }}>

            <Box sx={{ disply: 'inline-flex' }}>
                {showForm ?
                    <Box sx={{ width: '100%' }}>
                        <PaymentProcessorSelector />
                        <DialogActions>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={2}
                            >
                                <CancelButton onCancel={CloseBookingPayDialog} />
                                <PayButton onFinish={CloseBookingPayDialog} />
                            </Stack>
                        </DialogActions>

                    </Box>

                    :
                    <>
                        <Typography align='center' sx={{ p: 1 }}>
                            {translate('switch.portal.paymentslist.amountdue')}{CurrencyFormat(record?.amount)}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <DialogActions>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={2}
                                >
                                <CancelButton onCancel={CloseBookingPayDialog} />
                                    <Button onClick={() => { ShowPaymentForm(); }}>{translate('switch.portal.paymentslist.next')} </Button>
                                </Stack>
                            </DialogActions>

                        </Box>
                    </>}
            </Box>
        </PaymentContextProvider>

    )
}