import * as React from 'react'
import { createContext, useContext, useState, useEffect, useCallback } from 'react'
import { useGetOne, RecordContextProvider, useUpdate, ResourceContextProvider } from "react-admin";
import { usePortalContext } from './usePortal';


export const useMyAccount = () => {
    const context = useMyAccountContext()

    return context
}

export const MyAccountContext = createContext(undefined)

export const MyAccountContextProvider = ({ context, children }) => {
    const { settings } = usePortalContext();
    const { data: account, loading: recordLoading } = useGetOne("Account", { id: settings?.contact?.id },
        {
            enabled: settings?.contact?.id ? true : false,
            staleTime: 24 * 60 * 60 * 1000
        },
        { page: 1, perPage: 10 },
        { field: "id", order: "ASC" },
    );

    const brandColors = {
        "amazon": "#b37314",
        "amex": "#c0c0c0",
        "cirrcus": "#164363",
        "dankort": "#2f3596",
        "directdebit": "#36454F",
        "discover": "#7a4e25",
        "ebay": "#3d7027",
        "eway": "#a39822",
        "jcb": "#2249a3",
        "maestro": "#2249a3",
        "mastercard": "#2249a3",
        "paypal": "#2249a3",
        "sage": "#12590f",
        "shopify": "#459642",
        "skrill": "#3a1f78",
        "solo": "#5d198a",
        "visa": "#2b47cc",
        "visaelectron": "#2b47cc",
        "westernunion": "#36454f",
        "worldpay": "#2b47cc"
    };

    const getRandomHexColor = (brand) => {
        return brandColors[brand] || "#9ba0ba";
    }
    const [currentCard, setCurrentCard] = useState(null)
    const [dialogState, setDialogState] = useState(false);

    const Open = useCallback((item) => {
        setCurrentCard(item)
        setDialogState(true);
    }, []);
    const Close = useCallback(() => {
        setCurrentCard(null)
        setDialogState(false);
    }, []);

    const contextData = {
        account,
        recordLoading,
        getRandomHexColor,
        dialogState,
        setCurrentCard,
        currentCard,
        Open,
        Close,

    };

    return (
        <MyAccountContext.Provider value={contextData} >
            <RecordContextProvider value={account}>
                <ResourceContextProvider value="account">
                    {children}
                </ResourceContextProvider>
            </RecordContextProvider>
        </MyAccountContext.Provider >
    )
}

export const useMyAccountContext = () => useContext(MyAccountContext)