import React from 'react';
import {
  Box,
  Heading,
} from "grommet";


const DateBar = (props) => {
  const { displayDate, layout, screenType } = props;

  // if (screenType === 'abstractLeft'){
  //   return(
  //     <Box direction="row" background="brand" fill="horizontal" pad="16px" style={{justifyContent: 'right'}}>
        
  //     </Box>
  //   )
  // }
  return (
    <Box direction="row" align="center" background="brand" fill="horizontal" pad="small">
      <Heading level={layout.dateBar.level} color={layout.dateBar.colour} >{displayDate.toDateString()}</Heading>
    </Box>
  );
};

export default DateBar;