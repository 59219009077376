import React from 'react';
import {
    ReferenceField,
    TextField,
    useRecordContext,
    useTranslate
} from 'react-admin';
import {
    Grid, Card, Typography
} from '@mui/material';
import FormatDate from '../../../components/FormatDate';
import FormatTime from '../../Shared/FormatTime';
import { RepeatBookingContextProvider, useRepeatBooking } from '../../Contexts/useRepeatBooking';


const RepeatText = () => {
    const { ruleText } = useRepeatBooking()
    const t = useTranslate()

    if (!ruleText) return;
    return (<Typography> {t('switch.portal.booking.views.summary.repeatrule')}: {ruleText}</Typography>)
}

export const EnquirySummary = () => {
    const record = useRecordContext()
    const t = useTranslate()


    if (!record) return

    const showGuestNumber = (number) => {
        return number > 1 ? `${number} ${t('switch.portal.enquiry.guests')}` : `${number} ${t('switch.portal.enquiry.guest')}`
    }

    return (
        <RepeatBookingContextProvider>
            <Card variant='outlined' sx={{ width: '100%', p: 2 }}>
                <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                    <Grid item xs={12}>
                        <TextField variant='h5' source="publicEventDescription" />
                    </Grid>
                    <Grid item xs={6}>
                        {showGuestNumber(record?.numberOfGuests)}
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceField
                            label="Venue"
                            source="venueId"
                            reference="venue"
                            link={false}
                            queryOptions={{ staleTime: 5 * 60 * 1000 }}
                        >
                            <TextField source="name" />
                        </ReferenceField>
                    </Grid>
                    <Grid item xs={6}>
                        {t('switch.portal.enquiry.startdate')}: {FormatDate(record?.bookingDate)}
                    </Grid>
                    <Grid item xs={6}>
                        {record?.bookingDate != record.bookingEndDate && t('switch.portal.enquiry.enddate')}{record?.bookingDate === record.bookingEndDate ? null : ': ' + FormatDate(record?.bookingEndDate)}
                    </Grid>
                    <Grid item xs={6}>
                        {t('switch.portal.enquiry.starttime')}: {FormatTime(record?.startTime)}
                    </Grid>
                    <Grid item xs={6}>
                        {t('switch.portal.enquiry.endtime')}: {FormatTime(record?.endTime)}
                    </Grid>
                    <Grid item xs={12}>
                        <RepeatText/>
                    </Grid>
                </Grid>
            </Card>
        </RepeatBookingContextProvider>
    )
}