import { useState, useMemo } from 'react'
import {
    Resource,
    AdminContext,
    AdminUI,
    localStorageStore,
    CustomRoutes
} from "react-admin";
import Dashboard from "./Dashboard/Dashboard";
import { useParams } from "react-router-dom";
import {
    radiantDarkTheme,
    radiantLightTheme,
} from 'react-admin';
import authProvider from "./authProvider";
import { dataProvider } from "./dataProvider";
import { auth0 } from "./auth0";
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { PortalContextProvider } from "./Contexts/usePortal";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import english from "./Locale/en/translation.json";
import debug from "./Locale/debug/translation.json";
import englishMessages from 'ra-language-english';
import MyAccountParent from "./myAccount/MyAccountParent";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { BookingHistoryList } from "./Bookings/BookingHistoryList";
import { ShowBooking } from "./Bookings/ShowBooking";
import { CurrentBookingsList } from './Bookings/CurrentBookingsList';
import { InvoiceList } from './Invoices/InvoiceList';
import { InvoiceShow } from './Invoices/InvoiceShow';
import TodayIcon from '@mui/icons-material/Today';
import { CurrentEnquiryList } from './Enquires/CurrentEnquiryList';
import { ShowEnquiry } from './Enquires/ShowEnquiry/ShowEnquiry';
import { QuoteShow } from './Quote/QuoteShow';
import { QuoteList } from './Quote/QuoteList';
import { PaymentList } from './Payments/PaymentList';
import { PaymentShow } from './Payments/PaymentShow';
import { Route } from "react-router-dom";
import { Layout } from './Layout';
import { ShowRepeatBooking } from './Bookings/RepeatBookings/ShowRepeatBooking';


export const Portal = () => {
    const { client } = useParams();
    const auth = authProvider(client)
    const [loaded, setLoaded] = useState(false)
    const [permissions, setPermissions] = useState()
    const [themes, setThemes] = useState({ light: radiantLightTheme, dark: radiantDarkTheme })
    const [translations, setTranslations] = useState({
        english: {
            ...englishMessages,
            ...english
        },
        debug: {
            ...englishMessages,
            ...debug
        }
    })
    

    const languageList = permissions?.showDebug ? [
        { locale: 'en', name: 'English' },
        { locale: 'debug', name: 'Debug' },
    ] : [
        { locale: 'en', name: 'English' }
    ]

    function _setT(value) {
        setTranslations(value)
        setLoaded(true)
    }

    const i18nProvider = useMemo(() => polyglotI18nProvider(
        locale => {
            if (locale === 'debug') return translations?.debug;
            return translations?.english;
        },
        "en",
        languageList 
    ), [translations]);

    const STORE_VERSION = "2";
    return (
        <AdminContext
            basename={"/portal/" + client}
            authProvider={auth}
            dataProvider={dataProvider(auth0, client)}
            i18nProvider={i18nProvider}
            theme={themes?.light}
            darkTheme={themes?.dark}
            defaultTheme="dark"
            store={localStorageStore(STORE_VERSION)}
        >
        
            <PortalContextProvider setThemes={setThemes} setTranslations={_setT} loaded={loaded} setLoaded={setLoaded} setPermissions={setPermissions}>
                <AdminUI
                    dashboard={Dashboard}
                    logo={HomeIcon}
                    layout={Layout}
                    title="Portal"
                    requireAuth
                >
                    <Resource name="Quote" options={{ label: 'switch.portal.menu.quotes' }} list={QuoteList} show={QuoteShow} icon={EventIcon} />
                    <Resource name="Booking" options={{ label: 'switch.portal.menu.bookings' }} list={CurrentBookingsList} icon={EventAvailableIcon} show={ShowBooking} />
                    <Resource name="Enquiry" options={{ label: 'switch.portal.menu.enquiries' }} list={CurrentEnquiryList} show={ShowEnquiry} icon={TodayIcon} />
                    <Resource name="Payments" options={{ label: 'switch.portal.menu.payments' }} list={PaymentList} icon={PaymentsIcon} show={PaymentShow} />
                    <Resource name="History" options={{ label: 'switch.portal.menu.history' }} list={BookingHistoryList} icon={CalendarMonthIcon} show={ShowBooking} />
                    <Resource name="Invoice" options={{ label: 'switch.portal.menu.invoice' }} list={InvoiceList} icon={ReceiptIcon} show={InvoiceShow} />
                    <Resource name="repeatbooking" show={ShowRepeatBooking} />
                    <CustomRoutes >
                        <Route path="Account" element={<MyAccountParent />} />
                        <Route path="Card" element={<MyAccountParent />} />
                        <Route path="Dashboard" element={<Dashboard/>} />
                    </CustomRoutes>
                </AdminUI>
            </PortalContextProvider>
        </AdminContext>

    );
}
