import React, { } from "react";
import {
    Datagrid,
    DateField,
    NumberField,
    ReferenceField,
    TextField,
    useTranslate
} from 'react-admin';

export const QuoteListDesktop = () => {
    const t = useTranslate();
    return (
                <Datagrid rowClick="show">
                    <ReferenceField
                        label={t('switch.portal.quote.list.desktop.id')}
                        source="enquiryId"
                        reference="enquiry"
                        link={false}
                        queryOptions={{ staleTime: 5 * 60 * 1000 }}
                    >
                        <TextField source="switchURN" />
                    </ReferenceField>
                    <ReferenceField
                        label={t('switch.portal.quote.list.desktop.desc')}
                        source="enquiryId"
                        reference="enquiry"
                        link={false}
                        queryOptions={{ staleTime: 5 * 60 * 1000 }}
                    >
                        <TextField source="publicEventDescription" />
                    </ReferenceField>
                    <TextField label={t('switch.portal.quote.list.desktop.status')} source="status" />
                    <ReferenceField
                        label={t('switch.portal.quote.list.desktop.venue')}
                        source="venueId"
                        reference="venue"
                        link={false}
                        queryOptions={{ staleTime: 5 * 60 * 1000 }}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <DateField label={t('switch.portal.quote.list.desktop.expiry')} source="expiryDate" locales="en-GB" />
                    <NumberField source="net" label={t('switch.portal.quote.list.desktop.net')} options={{
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2
                    }} />
                    <NumberField source="tax" label={t('switch.portal.quote.list.desktop.tax')} options={{
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2
                    }} />
                   <NumberField source="gross" label={t('switch.portal.quote.list.desktop.gross')} options={{
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2
                    }} />
                </Datagrid>
    );
}
