import React from 'react'
import PlusMinusComponent from "./PlusMinusComponent"
import {
  TextInput,
  Grid,
  Box,
  Heading,
} from "grommet";
import { add, sub, set, format } from 'date-fns'

const NewBookingContent = (props) => {
  const {tab, setNewBooking, newBooking, dateState, setDateState, endTimeState, setEndTimeState, tSet, setTSet, pinCheck} = props

  function minCalc(arg) {
    let thisArg = Number.parseInt(arg);
      if (thisArg < 15){
        return 15
      }
      if (thisArg >= 15 && thisArg < 30){
        return 30
      }
      if (thisArg >= 30 && thisArg < 45){
        return 45
      }
      if (thisArg >= 45 && thisArg < 59){
        return 0
      }

  }
  
  if (!tSet) {
    const sMins = minCalc(newBooking.start.substring(3,5));
    const eMins = minCalc(newBooking.end.substring(3,5));
    
    const initialStartSet = set(dateState, {hours: newBooking.start.substring(0,2), minutes: sMins, seconds: 0})
    const initialEndSet = set(endTimeState, {hours: newBooking.end.substring(0,2), minutes: eMins, seconds: 0})
    setNewBooking({...newBooking,
      end: format(initialEndSet, 'k:mm'),
      start: format(initialStartSet, 'k:mm'),
      date: format(initialStartSet, 'yyyy-MM-dd')
    })
    setDateState(initialStartSet)
    setEndTimeState(initialEndSet);
    setTSet(true);
  }

  function addButton(type) {
    let result
    if (tab < 4) {
      result = add(dateState, {
        [type]:  type === "minutes" ? 15 : 1,
      })
      setDateState(result)
      setNewBooking({...newBooking,
        start: format(result, 'k:mm'),
        date: format(result, 'yyyy-MM-dd')
      })
    } else {
      result = add(endTimeState, {
        [type]:  type === "minutes" ? 15 : 1,
      })
      setEndTimeState(result)
      setNewBooking({...newBooking,
        end: format(result, 'k:mm'),
        date: format(result, 'yyyy-MM-dd')
      })
    }
  }
  
  function subButton(type) {
    let result
    if (tab < 4) {
      result = sub(dateState, {
        [type]:  type === "minutes" ? 15 : 1,
      })
      setDateState(result)
      setNewBooking({...newBooking,
        start: format(result, 'k:mm'),
        date: format(result, 'yyyy-MM-dd')
      })
    } else {
      result = sub(endTimeState, {
        [type]:  type === "minutes" ? 15 : 1,
      })
      setEndTimeState(result)
      setNewBooking({...newBooking,
        end: format(result, 'k:mm'),
        date: format(result, 'yyyy-MM-dd')
      })
    }
  }
  const buttonsFn = { 
    addButton: addButton, 
    subButton: subButton
  }
  switch (tab) {
    case 0:
      return (
        <Box>
          <TextInput
            placeholder="Enter Booking Code"
            size="xlarge"
            textAlign="center"
            value={newBooking.bookingCode}
            onChange={value =>
              setNewBooking({ ...newBooking, bookingCode: value })
            }
            style={{backgroundColor: '#fff'}}
          />
        </Box>
      )
      break;
    case 1: 
       if (pinCheck.id > 0) {
          return(
            <Box>
              <Heading textAlign="center" level={2}> Welcome {pinCheck.name}! </Heading>
              <Heading textAlign="center" level={4}> Please proceed to the next page. </Heading>
            </Box>
          )
        } else {
          return(
            <Box>
              <Heading textAlign="center" level={2}> {pinCheck.status}: {pinCheck.status === 404 && "Booking Code "}{pinCheck.title}. </Heading>
            </Box>
          )
        }

      break;
    case 2:
      return (
          <Grid
            rows={["small"]}
            columns={["20%", "20%", "20%"]}
            justifyContent="around"
            align="end"
            areas={[
              { name: 'day', start: [0, 0], end: [0, 0] },
              { name: 'month', start: [1, 0], end: [1, 0] },
              { name: 'year', start: [2, 0], end: [2, 0] },
            ]}
            fill
          >
            <Box gridArea="day">
              <PlusMinusComponent type="days" val={dateState.getDate()} buttonsFn={buttonsFn} />
            </Box>
            <Box gridArea="month">
              <PlusMinusComponent type="months" val={dateState.toLocaleString('default', { month: 'short' })} buttonsFn={buttonsFn} />
            </Box>
            <Box gridArea="year">
              <PlusMinusComponent type="years" val={dateState.getFullYear()} buttonsFn={buttonsFn}/>
            </Box>
          </Grid>
          
      )
      break;
    case 3:
      return (
        <Grid
        rows={["small"]}
        columns={["20%", "20%"]}
        justifyContent="around"
        align="end"
        areas={[
          { name: 'hour', start: [0, 0], end: [0, 0] },
          { name: 'mins', start: [1, 0], end: [1, 0] },
        ]}
        fill
      >
        <Box gridArea="hour">
          <PlusMinusComponent type="hours" val={dateState.getHours()} buttonsFn={buttonsFn} />
        </Box>
        <Box gridArea="mins">
          <PlusMinusComponent type="minutes" val={dateState.getMinutes()} buttonsFn={buttonsFn} />
        </Box>
      </Grid>
      )
      break;
    case 4:
      return (
        <Grid
          rows={["small"]}
          columns={["20%", "20%"]}
          justifyContent="around"
          align="end"
          areas={[
            { name: 'hour', start: [0, 0], end: [0, 0] },
            { name: 'mins', start: [1, 0], end: [1, 0] },
          ]}
          fill
        >
          <Box gridArea="hour">
            <PlusMinusComponent type="hours" val={endTimeState.getHours()} buttonsFn={buttonsFn}/>
          </Box>
          <Box gridArea="mins">
            <PlusMinusComponent type="minutes" val={endTimeState.getMinutes()} buttonsFn={buttonsFn}/>
          </Box>
        </Grid>
      )
      break;
  
    default:
      return <Box><h1>Element Not Found!</h1></Box>
      break;
  }
};

export default NewBookingContent;