import * as React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import { useResourceContext } from 'react-admin';

export const useBookingList = () => {
    const context = useBookingListContext()

    return context
}

export const BookingListContext = createContext(undefined)

const EnumViewState = {
    repeats: "repeatbooking",
    boookings:"booking"
}

export const BookingListContextProvider = ({ context, children }) => {

    const [currentView, SetcurrentView] = useState(EnumViewState.boookings)
    const switchLabel = currentView === EnumViewState.boookings ? "switch.portal.bookingshow.lists.buttons.regularhire" : "switch.portal.bookingshow.lists.buttons.singlebookings"
    const resource = useResourceContext()
    const showViewSwitch = resource !== "History" 
    // Use Effects

    function SwitchCurrentView() {
        if (currentView === EnumViewState.boookings) {
            SetcurrentView(EnumViewState.repeats)
        } else {
            SetcurrentView(EnumViewState.boookings)
        }
    }

    let contextData = {
        currentView,
        showViewSwitch,
        switchLabel ,
        SwitchCurrentView,
        ...context
    }

    return (
        < BookingListContext.Provider value={contextData} >
            {children}
        </BookingListContext.Provider >
    )
}

export const useBookingListContext = () => useContext(BookingListContext)