import React from 'react'


const TitlesArray = ['Enter Booking Pin', 'Verifing Booking Code', 'Set Booking Date', 'Set Start Time', 'Set End Time']

const Titles = (props) => {
  const {tab, newBooking} = props;

  switch (tab) {
    case 0:
      if (newBooking) {
        return TitlesArray[0]
      }
      return TitlesArray[3]
      break;
    case 1:
      if (newBooking) {
        return TitlesArray[1]
      }
      return TitlesArray[4]
      break;
    case 2:
      return TitlesArray[2]
      break
    case 3:
      return TitlesArray[3]
      break
    case 4:
      return TitlesArray[4]
      break
    default:
      break;
  }
}

export default Titles;