import { usePayment } from "../../contexts/PaymentContext";
import { Button } from '@mui/material';
import { useTranslate } from 'react-admin';


export const PayButton = (props) => {
    const { onFinish } = props;
    const translate = useTranslate()

    const {
        cardType,
        loading,
        paymentStatus,
        ready,
        processingType,
        PerformRedirect,
        savedCardStepWindCave,
        setSavedCardStepWindCave,
        savedCards,
        iFrameResult,
        processing,
        preRedirectStateSetter,
        monitoring
    } = usePayment();

    if (paymentStatus !== "succeeded") {
        if (processingType === "stripe" ) {
            return (
                <Button
                    id="payConfirm"
                    primary
                    disabled={
                        loading || !ready || paymentStatus === "processing" || monitoring
                    }
                    form={cardType === "newcard" ? "cardDetails" : "cvcRecapture"}
                    type="submit"
                > {translate('switch.portal.bookingshow.pay')} </Button>
            );
        } else {
            return (
                <>
                    {savedCardStepWindCave && (
                        <Button
                            sx={{ p: 1 }}
                            primary
                            disable={loading}
                            type="submit"
                            onClick={() => {
                                setSavedCardStepWindCave(false);
                                PerformRedirect();
                            }}
                        >{savedCards ? translate('switch.portal.invoiceshow.btns.payment') : translate('switch.portal.paymentslist.next')}</Button>
                    )}
                    {!savedCardStepWindCave && iFrameResult && (
                        <Button
                            sx={{ p: 1 }}
                            id="payConfirm"
                            primary
                            onClick={iFrameResult?.result === "success" ? onFinish : preRedirectStateSetter}
                            disabled={processing}
                        >{iFrameResult?.result === "success" ? translate('switch.portal.paymentslist.finish') : translate('switch.portal.paymentslist.retry')}</Button>
                    )}
                </>
            );
        }
    }
    return (
        <Button
            id="payConfirm"
            primary
            onClick={onFinish}
        >{translate('switch.portal.paymentslist.finish')} </Button>
    );
};


export const CancelButton = (props) => {
    const translate = useTranslate()
    const { onCancel } = props;
    const {
        paymentStatus
    } = usePayment();

    if (paymentStatus != "succeeded") return (<Button onClick={onCancel} > {translate('switch.portal.bookingshow.cancel')} </Button>);
    return null;
}