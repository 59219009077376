import React, { useState, useEffect } from 'react'
import Ticker from "react-ticker";
import
    {
        Box,
        Heading,
    } from "grommet";
let Parser = require("rss-parser");
let parser = new Parser();

const NewsTicker = props =>
{
    const { rssFeed, layout } = props;
    const { newsTickerTitle, newsTickerDesc } = layout
    const [newsItems, setNewsItems] = useState();
    const [refresh, setRefresh] = useState(false);

    useEffect(
        () =>
        {
            if (!newsItems || refresh === true)
            {
                const RSS_URL =
                    "https://proud-fog-9f24.dispace.workers.dev/corsproxy/?apiurl=" +
                    rssFeed;

                //const RSS_URL ="https://proud-fog-9f24.dispace.workers.dev/corsproxy/?apiurl=http://feeds.skynews.com/feeds/rss/uk.xml"
                parser.parseURL(RSS_URL, function (err, feed)
                {
                    if (err) throw err;
                    setRefresh(false);
                    setNewsItems(feed);
                });
            }
        },
        [refresh]
    );

    return (
        <Box background="brand">
            <Heading level={newsTickerTitle.level} color={newsTickerTitle.colour} style={{ whiteSpace: "nowrap", padding: '0px 12px' }}>
                {!newsItems ? "" : newsItems.title}
            </Heading>
            <Ticker speed={12} mode="await">
                {({ index }) =>
                {
                    if (!newsItems)
                        return (
                            <Heading level={newsTickerTitle.level} color={newsTickerTitle.colour} style={{ whiteSpace: "nowrap" }}>
                                Loading News Feed...
                            </Heading>
                        );
                    if (newsItems && index > newsItems.items.length - 1)
                    {
                        index = index % newsItems.items.length;
                    }
                    if (newsItems && index == newsItems.items.length - 1)
                    {
                        setRefresh(true);
                    }
                    const parser = new DOMParser();
                    const titleString = parser.parseFromString(`<!doctype html><body>${newsItems.items[index].title}`, 'text/html').body.textContent;
                    const contentString = parser.parseFromString(`<!doctype html><body>${newsItems.items[index].contentSnippet}`, 'text/html').body.textContent;

                    return (
                        <>
                            <Heading level={newsTickerTitle.level} color={newsTickerTitle.colour} style={{ whiteSpace: "nowrap" }}>
                                {titleString}
                            </Heading>
                            <Heading level={newsTickerDesc.level} color={newsTickerDesc.colour} style={{ whiteSpace: "nowrap" }}>
                                {contentString}
                            </Heading>
                        </>
                    );
                }}
            </Ticker>
        </Box>
    );
};

export default NewsTicker