import React, { Component } from "react";


export default class Api extends Component {
    constructor(props) {
        super(props);
        this.urlRoot = props.urlRoot;
        this.client = props.client
    }


    async calculateRefund(bookingId, bookingData) {
        const API_URL = '/Dispace/Switch/Portal/1.0.0'
        let url = API_URL + '/CalculateRefund/' + bookingId

        return this.client(url, { method: 'POST', body: JSON.stringify(bookingData), }).then(({ json }) => ({ data: json }));


    }

    async setupPayment(booking, saveCard, amount, response, paymentId, selectedCard) {
        const API_URL = '/Dispace/Switch/Portal/1.0.0'
        let url = API_URL + '/PaymentSession?bookingId=' + booking.id

        if (paymentId) {
            url += "&paymentScheduleId=" + paymentId;
        }

        if (selectedCard) {
            url += "&selectedCard=" + selectedCard.toString();
        }

        if (response && response?.length > 0) {
            url += "&responseRoute=" + response
        }

        return this.client(url, { method: 'POST', body: JSON.stringify(amount), }).then(({ json }) => (json));


    }

    async saveCardPreferences(contactId, cardId, saveDefault, consentToUse, providerRef, provider) {
        const API_URL = '/Dispace/Switch/Portal/1.0.0/'
        let url = API_URL + "saveCardPreferences/?contactId=" +
            contactId +
            "&saveDefault=" +
            saveDefault +
            "&consentToUse=" +
            consentToUse +
            "&providerRef=" +
            providerRef +
            "&provider=" +
            provider;


        if (cardId != null) {
            url = url + "&cardId=" +
                cardId
        }

        return this.client(url, { method: 'POST' }).then(({ json }) => (json));

    }

    async getPaymentStatus(bookingId, paymentId) {
        const API_URL = '/Dispace/Switch/Portal/1.0.0/'

        let url =
            API_URL +
            "GetPaymentStatus?bookingId=" +
            bookingId +
            "&paymentId=" +
            paymentId;

        return this.client(url, { method: 'GET' }).then(({ json }) => (json));

    }


    async MonitorPayment(paymentId) {
        const API_URL = '/Dispace/Switch/Portal/1.0.0/MonitorPayment'
        let url = API_URL + '?paymentId=' + paymentId

        return this.client(url, { method: 'GET' }).then(({ json }) => (json));

    }
}
