import React, { } from 'react';
import {
    TopToolbar,
    useRedirect,
    useTranslate,
    usePermissions
} from 'react-admin';
import {
    Button, Grid
} from '@mui/material';

import { CancelBookingDialog } from './CancelBookingDialog';
import { BookingPayment } from '../BookingPayment';
import { useBookingPayment } from '../../Contexts/useBookingPayment';
import { useBookingCancel } from '../../Contexts/useBookingCancel';

export const BookingShowToolBar = () => {
    const { OpenCancelDialog, allowCancel } = useBookingCancel();
    const { OpenDialog, isUnpaid } = useBookingPayment()
    const t = useTranslate();
    const redirect = useRedirect()

    const { permissions } = usePermissions();

    const canCancel = permissions?.allowBookingCancel;
    const canPay = permissions?.allowPayment;

    return (
        <TopToolbar sx={{ marginLeft: "auto", p: 2, borderRadius: 1 }}>
                        { canPay && <Grid item xs={4}  sx={{ mb: 1 }}>
                <Button disabled={!isUnpaid} onClick={OpenDialog}>{t("switch.portal.bookingshow.pay")}</Button>
            </Grid> }
            { canCancel && <Grid item xs={4}  sx={{ mb: 1 }}>
                <Button onClick={OpenCancelDialog} disabled={!allowCancel}>
                    {t('switch.portal.bookingshow.cancel')}
                </Button>
            </Grid>}
            <Grid item xs={4} sx={{ mb: 1 }}>
                <Button onClick={() => redirect('list', 'booking')}>{t('switch.portal.bookingshow.back')}</Button>
            </Grid>
            <CancelBookingDialog />
            <BookingPayment />
        </TopToolbar>
    );
};