import React, { } from "react";
import {
    DateField,
    NumberField,
    ReferenceField,
    TextField,
    useRecordContext,
    RecordContextProvider,
    useTranslate
} from 'react-admin';
import {
    Typography,
    Card,
    Grid
} from '@mui/material';
import FormattedTime from "../../Shared/FormattedTime";
import { GenericMobileListViews } from "../../Shared/GenericMobileListViews";


export const BookingResourcesMobile = () => {
    const record = useRecordContext();
    const t = useTranslate();
    const sortedDetails = record?.details.sort((a, b) => new Date(b.bookingDate) - new Date(a.bookingDate));

    const formatTime = (timeString) => {
        const date = new Date(`1970-01-01T${timeString}`);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };
    return (
        <>
            <GenericMobileListViews>
                {sortedDetails?.length === 0 ? (
                    <Grid item xs={12}>
                        <Card variant='outlined' sx={{ padding: 2, marginY: 1 }}>
                            <Typography variant="body2">
                                {t('switch.portal.booking.views.resource.mobile.noresult')}
                            </Typography>
                        </Card>
                    </Grid>
                ) : (
                    sortedDetails.map(detail => (
                        <Card variant='outlined' sx={{ width: '100%', p: 2, marginY: 1 }}>
                            <RecordContextProvider key={detail.id} value={detail}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <ReferenceField
                                            source="resourceId"
                                            reference="resources"
                                            link={false}
                                            queryOptions={{ staleTime: 5 * 60 * 1000 }}
                                        >
                                            <Typography variant="subtitle1">
                                                <TextField source="name" />
                                            </Typography>
                                        </ReferenceField>
                                        <Typography variant="subtitle1">
                                            <NumberField
                                                record={detail}
                                                source="lineItemCost"
                                                options={{
                                                    style: 'currency',
                                                    currency: 'GBP',
                                                    minimumFractionDigits: 2
                                                }}
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" paragraph>
                                            {t('switch.portal.booking.views.resource.mobile.bookingdate')}<DateField record={detail} source="bookingDate" showTime={false} />
                                        </Typography>
                                        <Typography variant="subtitle1" paragraph>
                                            {t('switch.portal.enquiry.starttime')}: {formatTime(detail.startTime)}
                                        </Typography>
                                        <Typography variant="subtitle1" paragraph>
                                            {t('switch.portal.enquiry.endtime')}: {formatTime(detail.endTime)}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {t('switch.portal.booking.views.resource.mobile.guests')}<TextField record={detail} source="guests" />
                                        </Typography>
                                    </Grid>
                                    {detail.notes ?
                                        <Grid item xs={12}>
                                            <div style={{ maxHeight: '60px', overflowY: 'auto' }}>
                                                <Typography variant="subtitle1" paragraph sx={{ maxWidth: 300, whiteSpace: 'pre-line' }}>
                                                    {t('switch.portal.booking.views.resource.mobile.notes')}<TextField record={detail} source="notes" />
                                                </Typography>
                                            </div>
                                        </Grid>
                                        : null
                                    }
                                </Grid>
                            </RecordContextProvider>
                        </Card>

                    ))
                )}
            </GenericMobileListViews>
        </>
    )
}