import React, { useState } from 'react'
import { Box, DataTable, Grid, Text } from "grommet";

import DateBar from "./DateBar";
import Title from "./Title";
import NewsTicker from "./NewsTicker";
import useInterval from '../../useInterval';

function getPageData(page, data, itemsPerPage) {
    if (data.length <= itemsPerPage) {
        return data;
    }
    else {
        const startIndex = (page - 1) * itemsPerPage;
        const itemCount = startIndex + itemsPerPage > data.length ? ((startIndex + itemsPerPage) - data.length) : itemsPerPage;
        const endIndex = startIndex + itemCount;

        return data.slice(startIndex, endIndex);
    }
}

function getPageInterval(timings, data, itemsPerPage) {
    let result = 10000;
    try {
        const agendaTiming = timings.filter(t => t.viewType == "agenda")[0].displayTime;
        const pages = Math.ceil(data.length / itemsPerPage)
        result = Math.ceil(agendaTiming / pages) * 1000;
    }
    catch {
        console.error(error);
    }

    return result;

}


const Agenda = props => {
    const { state, data, screenType } = props;
    const abstract1 = (state && state.theme.customColors.abstract1) ? state.theme.customColors.abstract1 : '#bcbcbc';
    const abstract2 = (state && state.theme.customColors.abstract2) ? state.theme.customColors.abstract2 : '#8c979f';
    const abstract3 = (state && state.theme.customColors.abstract3) ? state.theme.customColors.abstract3 : '#a6a9ae';
    const itemsPerPage = (state && state.itemsPerPage) ? state.itemsPerPage : 7;
    const [page, setPage] = useState(1);

    const pageData = getPageData(page, data, itemsPerPage);

    useInterval(() => {
        if (itemsPerPage < data.length) {
            if (page * itemsPerPage >= data.length) {
                setPage(1);
            }
            else {
                setPage(page + 1);
            }
        }
    }, getPageInterval(state.timings, data, itemsPerPage));

    const styles = {
        v2Box0: {
            height: '100vh',
            width: '100vw',
            backgroundColor: abstract1,
            top: '-65vh',
            position: 'absolute',
            transform: 'rotate(25deg)',
            right: '-50vw',
            zIndex: '997',
        },
        v2Box1: {
            height: '100vh',
            width: '100vw',
            backgroundColor: abstract2,
            top: '-60vh',
            position: 'absolute',
            transform: 'rotate(45deg)',
            right: '-50vw',
            zIndex: '998',
        },
        v2Box2: {
            height: '100vh',
            width: '100vw',
            backgroundColor: abstract3,
            top: '-79vh',
            position: 'absolute',
            transform: 'rotate(56deg)',
            right: '-50vw',
            zIndex: '999',
        },
        v3Box0: {
            height: '100vh',
            width: '100vh',
            backgroundColor: abstract1,
            top: '12.3vh',
            position: 'absolute',
            transform: 'rotate(35deg)',
            left: '-27vw',
            zIndex: '999'
        },
        gridBox: {
            margin: '5px 0px'
        },
        timeGrid: {
            borderRadius: '5px 0px 0px 5px'
        },
        resourceGrid: {
            borderRadius: '0px 5px 5px 0px'
        },
        restGrid: {
            boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)',
        },
        dataTable: {
            maxWidth: '80%',
        }
    }
    const columns = [
        {
            property: "start",
            size: "xsmall",
            align: "end",
            render: datum => (<Box gridArea="start" pad={{ vertical: 'xsmall' }}>
                <Text size="large">{datum.start}</Text>
            </Box>
            ),
        },
        {
            render: datum => "-",
            size: "xxsmall",
            align: "center",
            sortable: false
        },
        {
            property: "end",
            size: "xsmall",
            align: "start",
            render: datum => (<Box gridArea="ebd" pad={{ vertical: 'xsmall' }}>
                <Text size="large">{datum.end}</Text>
            </Box>
            ),
        },
        {
            property: "description",
            size: "large",
            render: datum => (<Box gridArea="res" pad={{ vertical: 'xsmall' }}>
                <Text size="large">{datum.description}</Text>
            </Box>
            ),
        },
        {
            property: "resource",
            align: "end",
            size: "large",
            render: datum => (<Box gridArea="res" pad={{ vertical: 'xsmall' }}>
                <Text size="large">{datum.resource}</Text>
            </Box>
            ),
        }
    ];

    switch (screenType) {
        case 'reception':
            return (
                <Box align="center" pad="medium">
                    <DataTable
                        columns={columns.map(col => ({ ...col }))}
                        data={pageData}
                        sortable
                        size="medium"
                        primaryKey="uniqueId"
                    />
                </Box>
            )
            break;
        case 'abstractRight':
            return (
                <Box fill>
                    <div style={styles.v2Box0}></div>
                    <div style={styles.v2Box1}></div>
                    <div style={styles.v2Box2}></div>
                    <Box flex={false} tag="header">
                        <Title title={state.title} logo={state.theme.logoImage} />
                        <DateBar displayDate={state.DisplayDate} layout={state.layout} />
                    </Box>
                    <Box flex overflow="auto" pad={{ vertical: 'small' }} style={styles.dataTable}>
                        <DataTable
                            columns={columns.map(col => ({ ...col }))}
                            data={pageData}
                            sortable
                            size="medium"
                            primaryKey="uniqueId"
                        />
                    </Box>

                </Box>
            )
        case 'abstractLeft':
            return (
                <Box fill>
                    <div style={styles.v3Box0}>
                        {/* <DateBar displayDate={state.DisplayDate} layout={state.layout} screenType={screenType} /> */}
                    </div>
                    <Box flex={false} tag="header">
                        <Title title={state.title} screenType={screenType} displayDate={state.DisplayDate} layout={state.layout} abstractColour={props.state.theme.customColors.abstract1} logo={props.state.theme.logoImage} />
                    </Box>
                    <Box flex overflow="auto" pad={{ vertical: 'small' }}>
                        {pageData.map(item => {
                            return (
                                <Grid
                                    rows={['xxsmall']}
                                    columns={['large', 'small', 'medium', 'small']}
                                    areas={[
                                        { name: 'spacer', start: [0, 0], end: [0, 0] },
                                        { name: 'rest', start: [1, 0], end: [3, 0] },
                                    ]}
                                    style={styles.gridBox}
                                >
                                    <Box gridArea="spacer" pad="small">
                                    </Box>
                                    <Box gridArea="rest" style={styles.restGrid}>
                                        <Grid
                                            rows={['xxsmall']}
                                            columns={['medium', 'large', 'large']}
                                            areas={[
                                                { name: 'time', start: [0, 0], end: [0, 0] },
                                                { name: 'desc', start: [1, 0], end: [1, 0] },
                                                { name: 'resource', start: [2, 0], end: [2, 0] },
                                            ]}
                                        >
                                            <Box gridArea="time" background="brand" pad="small" style={styles.timeGrid}>
                                                <Text size="large">{item.start} - {item.end}</Text>
                                            </Box>
                                            <Box gridArea="desc" background="brand" pad="small" >
                                                <Text size="large">{item.description}</Text>
                                            </Box>
                                            <Box gridArea="resource" background="brand" pad="small" style={styles.resourceGrid}>
                                                <Text size="large">{item.resource}</Text>
                                            </Box>
                                        </Grid>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Box>
                </Box>
            )
        default:
            return null;
    }
};

export default Agenda;