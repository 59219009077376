import React, { useState, useEffect } from "react";
import { Box } from "grommet";

import DateBar from "./reception/DateBar";
import Title from "./reception/Title";
import NewsTicker from "./reception/NewsTicker";
import Agenda from "./reception/Agenda";
import MainImageWithText from "./reception/MainImageWithText";
import { useSSE } from "react-hooks-sse";

const CurrentView = props => {
  const { state, currentView, random, displayItem } = props;
  const [eventData, SetEventData] = useState(prepareEventData(state.events));
  const [addData, SetAddData] = useState(prepareAddData(state.adverts));

  switch (currentView) {
    case 0:
          return <Agenda data={state.agenda} screenType={state.screenType} state={state} />;
      break;
    case 1:
      if (!eventData || eventData.length === 0) {
          return <Agenda data={state.agenda} screenType={state.screenType} state={state} />;
      } else {
        var index = null;

        if (!random && displayItem) {
          index = eventData.findIndex(event => event.id === displayItem);
        }

        return <EventDisplay data={eventData} state={state} index={index} />;
      }

      break;
    case 2:
      if (!addData || addData.length === 0) {
        return <Agenda data={state.agenda} />;
      } else {
        var index = null;

        if (!random && displayItem) {
          index = addData.findIndex(add => add.id === displayItem);
        }

        return <AddDisplay data={addData} state={state} index={index} />;
      }

      break;
  }
};

function prepareEventData(data) {
  var eventData = [];
  data.forEach(function(event, index) {
    eventData.push({
      id: event.eventid,
      title: event.event.displayInfo.title,
      image: event.event.image.imageUrl,
      line1: event.event.displayInfo.line1,
      line2: event.event.displayInfo.line2,
      date: new Date(event.event.startDate),
      start: event.event.startTime,
      end: event.event.endTime
    });
  });

  return eventData;
}

function prepareAddData(data) {
  var AddData = [];
  data.forEach(function(add, index) {
    AddData.push({
      id: add.advertid,
      title: add.advert.title,
      image: add.advert.image.imageUrl,
      line1: add.advert.line1,
      line2: add.advert.line2
    });
  });

  return AddData;
}

const EventDisplay = props => {
  const { data, index, state } = props;

  let eventItem = !index ? getRandomInt(0, data.length - 1) : index;

  return (
    <MainImageWithText
      state={state}
      imageUrl={data[eventItem].image}
      title={data[eventItem].title}
      line1={data[eventItem].line1}
      line2={data[eventItem].line2}
    />
  );
};

const AddDisplay = props => {
  const { data, index, state } = props;

  let eventItem = !index ? getRandomInt(0, data.length - 1) : index;

  return (
    <MainImageWithText
      state={state}
      imageUrl={data[eventItem].image}
      title={data[eventItem].title}
      line1={data[eventItem].line1}
      line2={data[eventItem].line2}
    />
  );
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getviewType(type) {
  switch (type) {
    case "agenda":
      return 0;
      break;
    case "event":
      return 1;
      break;
    case "advert":
      return 2;
      break;
    default:
      return 0;
  }
}

const ReceiptionScreen = props => {
  const { state, setAgenda } = props;
  const [timingIndex, setTimingIndex] = useState(0);
  const [timings, setTimings] = useState(
    state.timings.sort(
      (a, b) =>
        a.displayOrder < b.displayOrder
          ? -1
          : a.displayOrder > b.displayOrder
            ? 1
            : 0
    )
  );
  const newData = useSSE("message", state);

  useEffect(
    () => {
      const interval = setInterval(() => {
        const nextTiming =
          timings.length - 1 < timingIndex + 1 ? 0 : timingIndex + 1;
        setTimingIndex(nextTiming);
      }, timings[timingIndex].displayTime * 1000);
      return () => clearInterval(interval);
    },
    [timingIndex]
  );

  useEffect(
    () => {
      if (
        state.signedIn &&
        JSON.stringify(newData.agenda) != JSON.stringify(state.agenda)
      ) {
        setAgenda(newData.agenda);
      }
    },
    [newData]
  );

  return (
    <Box fill>
      <Box flex={false} tag="header">
        <Title title={state.title} screenType={state.screenType} logo={state.theme.logoImage} />
        <DateBar displayDate={state.DisplayDate} layout={state.layout} />
      </Box>
      <Box flex overflow="auto">
        <CurrentView
          state={state}
          currentView={getviewType(timings[timingIndex].viewType)}
          random={state.timings[timingIndex].random}
          displayItem={timings[timingIndex].displayItemId}
        />
      </Box>
      <Box flex={false}>
        <NewsTicker rssFeed={state.newsFeedURL} layout={state.layout} />
      </Box>
    </Box>
  );
};

export default ReceiptionScreen;
