import React, { } from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    NumberField,
    useTranslate,
    useShowController,
    TopToolbar,
    ListButton
} from 'react-admin';
import {
    Button,
} from '@mui/material';

const ShowActionBar = () => {
    const t = useTranslate()

    return (
        <TopToolbar sx={{ marginLeft: "auto", p: 2, borderRadius: 1 }}>
            <ListButton label={t('switch.portal.invoiceshow.btns.back')} />
        </TopToolbar>
    )
}

export const InvoiceShow = () => {
    const translate = useTranslate();
    const { record } = useShowController();

    const btnStyles = {
        width: 150,
        mt: 1,
        mb: 1
    }

    const handleOpenUrl = (url) => {
        window.open(url, "_blank");
    };

    return (
        <Show actions={<ShowActionBar />}>
            <SimpleShowLayout>
                <TextField source="switchURN" label={translate('switch.portal.invoiceshow.reference')} />
                <NumberField source="net"
                    label={translate('switch.portal.invoiceshow.net')}
                    options={{
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2
                    }} />
                <NumberField source="tax"
                    label={translate('switch.portal.invoiceshow.tax')}
                    options={{
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2
                    }} />
                <NumberField source="gross"
                    label={translate('switch.portal.invoiceshow.gross')}
                    options={{
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2
                    }} />
                <TextField source="paymentTerms" label={translate('switch.portal.invoiceshow.terms')} />
                {record?.paymentLink && <Button sx={btnStyles} onClick={() => handleOpenUrl(record?.paymentLink)} variant="contained">{translate('switch.portal.invoiceshow.btns.payment')}</Button>}
                <Button sx={btnStyles} onClick={() => handleOpenUrl(record?.url)} variant="contained">{translate('switch.portal.invoiceshow.btns.invoice')}</Button>
            </SimpleShowLayout>
        </Show>
    )
}