import * as React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import UseStripePromise from '../CreateStripePromise'
import { usePortalManagePayment } from './PortalManagePaymentContext'
import { useTheme } from '@mui/material/styles'


export const usePayment = () => {
    const context = usePaymentContext()

    return context
}

export const PaymentContext = createContext(undefined)

export const PaymentContextProvider = ({ context, children, handleNext }) => {
    const { savedCards: savedCardMasterList, stripe, api, booking, amount: requstedAmount, saveCard } = context;
    const [consent, setConsent] = React.useState(true);
    const [saveDefault, setSaveDefault] = React.useState(true);
    const [savedCards, setSavedCards] = React.useState();
    const [hasSavedCards, setHasSavedCard] = useState();
    const [defaultCard, setDefaultCard] = useState();
    const [selectedCard, SetSelectedCard] = React.useState(hasSavedCards && defaultCard ? defaultCard : null);
    const [errorMessage, SetError] = useState();
    const [paymentRecord, SetPaymentRecord] = useState();
    const [paymentStatus, setPaymentStatus] = React.useState("disabled");
    const [ready, setReady] = useState(false);
    const [amount, setAmount] = useState(requstedAmount != null ? requstedAmount : 0);
    const createStripePromise = UseStripePromise();
    const stripePromise = stripe ? createStripePromise(stripe.publishableKey, stripe.connectedAccountId) : null;
    const [readyToSetupPayment, setReadyToSetupPayment] = useState(false);
    const [processingType, setProcessingType] = useState();
    const [redirectURL, setRedirectUrl] = useState();
    const [paymentProvider, setPaymentProvider] = useState();
    const [payScheduleId, setPayScheduleId] = useState();
    const [showIFrame, setShowIFrame] = useState(false);
    const [savedCardStepWindCave, setSavedCardStepWindCave] = useState(true);
    const [iFrameResult, setiFrameResult] = useState();
    const [processing, setProcessing] = useState(false);
    const [stripeStyleOptions, setStripeStyleOptions] = useState()
    const [monitoring, setMonitoring] = useState(false)

    const theme = useTheme()
    const portalContext = usePortalManagePayment();
    const SaveBookingWithPendingPayment = portalContext?.SaveBookingWithPendingPayment;

    const ChangeDefault = (event) => {
        setSaveDefault(event.target.checked);
    };

    const ChangeConsent = (event) => {
        setConsent(event.target.checked);
    };
    const [cardType, SetCardType] = React.useState(hasSavedCards ? 'saved' : 'newcard');


    const handleChangeCard = (event) => {
        var card = savedCards.filter(c => c.id == (event.target).value)[0]
        SetSelectedCard(card);
        if (cardType != "saved") SetCardType('saved');
    };

    const paymentCompleted = (payment) => {
        setMonitoring(true)
        const cardId = cardType == 'saved' ? selectedCard.id : null;
        const contactID = booking.contactId;
        const stripeRef = payment.payment_method;

        api?.saveCardPreferences(contactID, cardId, saveDefault, consent, stripeRef, "stripe")
            .then(result => {
                if (result) {
                    if (result.hasOwnProperty("error")) {
                        SetError(result.error.message);
                    }
                } else {
                    SetError("Error Setting Up Payment");
                }
            });


        api?.MonitorPayment(paymentRecord?.id).then(result => {
            setMonitoring(false)
            if (handleNext) handleNext();
        })
    };

    useEffect(() => {
        if (!processingType) return;
        if (!savedCardMasterList) return;
        const providersCards = savedCardMasterList.filter(c => c.paymentProvider === processingType);
        setSavedCards(providersCards)
        setHasSavedCard(providersCards && providersCards.length > 0 ? true : false);
        const providerDefault = providersCards.filter(c => c.default == true).length > 0 ? providersCards.filter(c => c.default == true)[0] : null;
        setDefaultCard(providerDefault);

    }, [savedCardMasterList, processingType])

    useEffect(() => {

        if (!iFrameResult) return;
        if (iFrameResult.result != "success") {
            setShowIFrame(false)
            SetError(iFrameResult.result)
            IFrameFailed(iFrameResult);
        }
        else {
            setShowIFrame(false)
            IFrameCompleted(iFrameResult);
        }
    }, [iFrameResult])


    useEffect(
        () => {
            if (hasSavedCards && cardType != 'saved') {
                SetCardType('saved');
            }
        },
        [savedCards]
    );


    useEffect(
        () => {
            if (paymentRecord != null && amount != paymentRecord.amount) {
                setAmount(paymentRecord.amount)
            }
        },
        [paymentRecord]
    );

    useEffect(
        () => {
            if (hasSavedCards && defaultCard && !selectedCard) {
                SetSelectedCard(defaultCard);
            }
            if (hasSavedCards && !defaultCard) {
                SetSelectedCard(savedCards[0])
            }
        },
        [defaultCard]
    );

    useEffect(
        () => {
            if (!readyToSetupPayment) {
                if (booking != null && booking.hasOwnProperty("paymentSchedule") && paymentRecord == null) {
                    let stripePayment = booking.paymentSchedule.filter(
                        p =>
                            p.paymentMethod.processingType == "stripe" &&
                            p.paymentStatus === "awaitingPayment"
                    ).sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
                    if (stripePayment && stripePayment.length > 0) {
                        setProcessingType("stripe");
                        setReadyToSetupPayment(true)
                        setAmount(stripePayment[0].amount)
                    }

                    let windCavePayment = booking.paymentSchedule.filter(
                        p =>
                            p.paymentMethod.processingType == "windCave" &&
                            p.paymentStatus === "awaitingPayment" &&
                            p.clientSecret === null
                    ).sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
                    if (windCavePayment && windCavePayment.length > 0) {
                        setProcessingType("windCave");
                        setAmount(windCavePayment[0].amount)
                        setPayScheduleId(windCavePayment[0].id)
                    }


                    let pay360Payment = booking.paymentSchedule.filter(
                        p =>
                            p.paymentMethod.processingType == "pay360SCP" &&
                            p.paymentStatus === "awaitingPayment"
                    ).sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
                    if (pay360Payment && pay360Payment.length > 0) {
                        setProcessingType("pay360SCP");
                        setAmount(pay360Payment[0].amount)
                        setPayScheduleId(pay360Payment[0].id)
                    }



                    let wpmPayment = booking.paymentSchedule.filter(
                        p =>
                            p.paymentMethod.processingType == "wpm" &&
                            p.paymentStatus === "awaitingPayment"
                    ).sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
                    if (wpmPayment && wpmPayment.length > 0) {
                        setProcessingType("WPM");
                        setAmount(wpmPayment[0].amount)
                        setPayScheduleId(wpmPayment[0].id)
                    }
                }
            }
        },
        [booking]
    );

    function PerformRedirect() {
        setProcessing(true);
        SetError();
        const response = window.location.origin;
        const cardId = cardType === 'saved' ? selectedCard?.id : null;

        if (booking != null && booking.hasOwnProperty("paymentSchedule") && paymentRecord == null) {
            api
                .setupPayment(booking, true, amount, response, payScheduleId, cardId)
                .then(result => {
                    if (result) {
                        if (result.hasOwnProperty("error")) {
                            SetError(result.error.message);
                            setProcessing(false)
                        } else {
                            if (
                                result.hasOwnProperty("paymentSchedule") &&
                                result.paymentSchedule.length > 0
                            ) {
                                let payment = result.paymentSchedule.filter(
                                    p =>
                                        (p.paymentMethod.processingType == "windCave" ||
                                            p.paymentMethod.processingType == "pay360SCP" ||
                                            p.paymentMethod.processingType == "wpm")
                                        &&
                                        p.paymentStatus === "awaitingPayment" &&
                                        p.reference !== null
                                ).sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
                                if (payment && payment.length > 0) {
                                    if (
                                        payment[0].redirectURL !== null &&
                                        payment[0].redirectURL.length > 0
                                    ) {
                                        SetPaymentRecord(payment[0]);
                                        setShowIFrame(true);
                                        setRedirectUrl(payment[0].redirectURL);
                                        setProcessing(false)

                                    }
                                }
                            }
                        }
                    } else {
                        SetError("Error Setting Up Payment");
                        setProcessing(false)
                    }
                });
        }
        else {
            if (paymentRecord) {
                setShowIFrame(true);
                setRedirectUrl(paymentRecord.redirectURL);
            }
        }

    }

    function IFrameCompleted(iframeInfo) {

        const cardId = cardType == 'saved' ? selectedCard.id : null;
        const contactID = booking.contactId;
        let ThirdPartyCardRef = "";
        setProcessing(true);

        if (booking != null && paymentRecord != null) {

            api
                .getPaymentStatus(booking?.id, paymentRecord?.id)
                .then(result => {
                    if (result) {
                        setMonitoring(true)

                        switch (iframeInfo?.provider) {
                            case "WindCave":
                                ThirdPartyCardRef = result?.sessionData?.transactions?.[0]?.card?.id;
                                api
                                    .saveCardPreferences(contactID, cardId, saveDefault, consent, ThirdPartyCardRef, "windCave")
                                    .then(result => {
                                        if (result) {
                                            if (result.hasOwnProperty("error")) {
                                                SetError(result.error.message);
                                                setProcessing(false);
                                            }
                                            else {
                                                if (handleNext) {
                                                    handleNext()
                                                }

                                                if (SaveBookingWithPendingPayment) {
                                                    const updatedPayment = {
                                                        ...paymentRecord,
                                                        paymentStatus: "paid"
                                                    };

                                                    SaveBookingWithPendingPayment(updatedPayment);
                                                }
                                                setProcessing(false);
                                            }
                                        } else {
                                            SetError("Error Setting Up Payment");
                                            setProcessing(false);
                                        }
                                    });
                                break;
                            case "WPM":
                            case "Pay360SCP":
                                if (handleNext) {
                                    handleNext()
                                }

                                if (SaveBookingWithPendingPayment) {
                                    SaveBookingWithPendingPayment(result);
                                }
                                setProcessing(false);
                        }


                        api?.MonitorPayment(paymentRecord?.id).then(result => {
                            setMonitoring(false)
                        })
                    }
                });
        }
    }

    function IFrameFailed(iframeInfo) {
        if (booking != null && paymentRecord != null) {
            api
                .getPaymentStatus(booking?.id, paymentRecord?.id)
                .then(result => {
                    switch (iframeInfo?.provider) {
                        case "WindCave":
                            if (result) {
                                SetError(result?.sessionData?.transactions?.[0]?.responseText);
                            }
                            break;
                        case "WPM":
                        case "Pay360SCP":
                            if (result) {
                                SetError(result?.sessionData?.paymentResult?.item?.errorMessage);
                            }
                            break;
                    }
                });
        }

        SetPaymentRecord(null)
    }

    useEffect(
        () => {
            if (readyToSetupPayment) {
                if (booking != null && booking.hasOwnProperty("paymentSchedule") && paymentRecord == null) {
                    api?.setupPayment(booking, saveCard, amount)
                        .then(result => {
                            if (result) {
                                if (result.hasOwnProperty("error")) {
                                    SetError(result.error.message);
                                } else {
                                    if (
                                        result.hasOwnProperty("paymentSchedule") &&
                                        result.paymentSchedule.length > 0
                                    ) {
                                        let payment = result.paymentSchedule.filter(
                                            p =>
                                                p.paymentMethod.processingType == "stripe" &&
                                                p.paymentStatus === "awaitingPayment" &&
                                                p.clientSecret !== null
                                        ).sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
                                        if (payment && payment.length > 0) {
                                            if (
                                                payment[0].clientSecret !== null &&
                                                payment[0].clientSecret.length > 0
                                            ) {
                                                SetPaymentRecord(payment[0]);
                                            }
                                        }
                                    }
                                }
                            } else {
                                SetError("Error Setting Up Payment");
                            }
                        });
                }
            }
        },
        [paymentRecord, booking, readyToSetupPayment]
    );
    useEffect(() => {
        setPaymentProvider(true)
    }, [readyToSetupPayment])
    const loading = paymentRecord === paymentProvider;

    const preRedirectStateSetter = () => {
        setProcessing(false)
        SetPaymentRecord(null);
        setShowIFrame(false);
        setRedirectUrl(null);
        setProcessing(false)
        setiFrameResult(null)
        setSavedCardStepWindCave(true)
    }


    useEffect(() => {

        setStripeStyleOptions({
            terms: {
                card: "never"
            }
        })

        if (theme.palette.mode == 'dark') {
            const newOptions = {
                appearance: {
                    theme: 'night',
                },
                style: {
                    base: {
                        color: 'white'
                    }
                }

            }
            setStripeStyleOptions({ ...stripeStyleOptions, ...newOptions })
        } else {
            const newOptions = {
                appearance: {
                    theme: 'stripe',
                },
                style: {
                    base: {
                        color: 'black'
                    }
                }

            }
            setStripeStyleOptions({ ...stripeStyleOptions, ...newOptions })

        }
    }, [booking])

    function SetWPMRedirect(sessionData) {
        const placeHolder = '<redirecturl><![CDATA[https://www.live-booking.co.uk]]></redirecturl>'
        const target = window.location.href.includes("portal") ? window.location : `${window.location.origin}/Payment/${booking?.switchURN}/${paymentRecord?.id}?monitor=1`
        const newLocation = `<redirecturl><![CDATA[${target}]]></redirecturl>`
        

        return sessionData.replace(placeHolder, newLocation);
    }


    let contextData = {
        consent: consent,
        ChangeConsent: ChangeConsent,
        saveDefault: saveDefault,
        ChangeDefault, ChangeDefault,
        selectedCard: selectedCard,
        SetSelectedCard: SetSelectedCard,
        hasSavedCards: hasSavedCards,
        defaultCard: defaultCard,
        stripePromise: stripePromise,
        stripeOptions: paymentRecord ? {
            clientSecret: paymentRecord.clientSecret
        } : null,
        amount: amount,
        savedCards: savedCards,
        error: errorMessage,
        SetError: SetError,
        secret: paymentRecord ? paymentRecord.clientSecret : null,
        cardType: cardType,
        handleChangeCard: handleChangeCard,
        SetCardType: SetCardType,
        paymentRecord: paymentRecord,
        loading: loading,
        paymentCompleted: paymentCompleted,
        paymentStatus: paymentStatus,
        setPaymentStatus: setPaymentStatus,
        ready: ready,
        setReady: setReady,
        processingType: processingType,
        PerformRedirect: PerformRedirect,
        redirectURL: redirectURL,
        setRedirectUrl,
        showIFrame,
        setShowIFrame,
        setiFrameResult,
        processing,
        savedCardStepWindCave: savedCardStepWindCave,
        setSavedCardStepWindCave: setSavedCardStepWindCave,
        setSaveDefault,
        SetSelectedCard,
        iFrameResult,
        preRedirectStateSetter,
        stripeStyleOptions,
        SetWPMRedirect,
        monitoring

    }

    return (
        <PaymentContext.Provider value={contextData}>
            {children}
        </PaymentContext.Provider>
    )
}

export const usePaymentContext = () => useContext(PaymentContext)
