import React, { } from 'react';
import { Show, useTranslate, TopToolbar, ListButton, useRecordContext } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { RepeatBookingShowDesktop } from './RepeatBookingShowDesktop';
import { RepeatBookingContextProvider } from '../../Contexts/useRepeatBooking';
import { RepeatBookingShowMobile } from './RepeatBookingShowMobileView';


const ShowActionBar = () => {
    const t = useTranslate()

    return (
        <TopToolbar sx={{ marginLeft: "auto", p: 2, borderRadius: 1 }}>
            <ListButton resource="booking" label={t('switch.portal.booking.views.repeat.list')} />
        </TopToolbar>
    )
}

const Title = () => {
    const t = useTranslate()
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return null;
    return record?.switchURN;
}

export const ShowRepeatBooking = () => {
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    )
   

    return (
        <Show actions={<ShowActionBar />} title={<Title/>}>
            <RepeatBookingContextProvider>
                {isSmall ? <RepeatBookingShowMobile/> : <RepeatBookingShowDesktop />}
            </RepeatBookingContextProvider>
        </Show>
    );
};