import * as React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import {
    useRedirect,
    useResourceContext
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';


export const usePayBooking = () => {
    const context = usePayBookingContext()

    return context
}

export const PayBookingContext = createContext(undefined)

export const PayBookingContextProvider = ({ context, children }) =>
{
    const [bookingPayDialogOpen, setBookingPayDialogOpen] = useState(false)
    const [showForm, setShowForm] = useState(true);
    const redirect = useRedirect()
    const resource = useResourceContext()
    const location = useLocation()
    const queryClient = useQueryClient();

    const OpenBookingPayDialog = () => {
        return setBookingPayDialogOpen(true)
    }

    const CloseBookingPayDialog = () => {
        queryClient.invalidateQueries(['Booking']);
        queryClient.invalidateQueries(['Payments']);
        redirect('list', resource)
        return setBookingPayDialogOpen(false)
    }

    const ShowPaymentForm = () => {
        setShowForm(true)
    }

    useEffect(() => {
        if (location?.state?.dialog?.dialogOpen) {
            OpenBookingPayDialog()
            setShowForm(true)
        }
    }, [location])



    let contextData = {
        ...context,
        OpenBookingPayDialog,
        bookingPayDialogOpen,
        CloseBookingPayDialog,
        ShowPaymentForm,
        showForm
    }

    return (
        < PayBookingContext.Provider value = { contextData } >
        { children }
        </PayBookingContext.Provider >
    )
}

export const usePayBookingContext = () => useContext(PayBookingContext)