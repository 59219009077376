import React, { } from "react";
import {
    ReferenceField,
    TextField,
    useRecordContext,
    useTranslate
} from 'react-admin';
import {
   Grid, Card
} from '@mui/material';

export const BookingContact = () => {
    const record = useRecordContext()
    const t = useTranslate()

    if (!record) return

    return (
        <Card variant='outlined' sx={{ width: '100%', p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid xs={12}>
                        <ReferenceField
                            source="bookingOwnerUserId"
                            reference="user"
                            link={false}
                            queryOptions={{ staleTime: 5 * 60 * 1000 }}
                        >
                            {t('switch.portal.booking.views.contact.email')} <TextField source="email" />
                        </ReferenceField>
                    </Grid>
                    <Grid xs={12}>
                        <ReferenceField
                            label="Venue"
                            source="venueId"
                            reference="venue"
                            link={false}
                            queryOptions={{ staleTime: 5 * 60 * 1000 }}
                        >
                            {t('switch.portal.booking.views.contact.phonenumber')} <TextField source="telephone" />
                        </ReferenceField>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid>
                        {t('switch.portal.booking.views.contact.address')}
                    </Grid>
                    <Grid item xs={12}>
                        <ReferenceField
                            label="Venue"
                            source="venueId"
                            reference="venue"
                            link={false}
                            queryOptions={{ staleTime: 5 * 60 * 1000 }}
                        >
                            <TextField source="name" />
                        </ReferenceField>

                    </Grid>

                    <Grid item xs={12}>
                        <ReferenceField
                            label="Venue"
                            source="venueId"
                            reference="venue"
                            link={false}
                            queryOptions={{ staleTime: 5 * 60 * 1000 }}
                        >
                            <TextField source="address1" />
                        </ReferenceField>
                    </Grid>
                    <Grid item xs={12}>
                        <ReferenceField
                            label="Venue"
                            source="venueId"
                            reference="venue"
                            link={false}
                            queryOptions={{ staleTime: 5 * 60 * 1000 }}
                        >
                            <TextField source="address2" />
                        </ReferenceField>
                    </Grid>
                    <Grid item xs={12}>
                        <ReferenceField
                            label="Venue"
                            source="venueId"
                            reference="venue"
                            link={false}
                            queryOptions={{ staleTime: 5 * 60 * 1000 }}
                        >
                            <TextField source="postCode" />
                        </ReferenceField>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}