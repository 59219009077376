import React from 'react';
import {
    DateField,
    NumberField,
    ReferenceField,
    TextField,
    useRecordContext,
    Datagrid,
    ArrayField,
    useTranslate
} from 'react-admin';
import FormattedTime from '../../../Shared/FormattedTime';

export const ResourcesDesktopView = () => {
    const record = useRecordContext();
    const t = useTranslate();
    if (!record) return null;

    return (
        <>
            <ArrayField source="details" sort={{ field: 'bookingDate', order: 'DESC' }}>
                <Datagrid bulkActionButtons={false}>
                    <ReferenceField
                        label='switch.portal.enquiry.item'
                        source="resourceId"
                        reference="resources"
                        link={false}
                        queryOptions={{ staleTime: 5 * 60 * 1000 }}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                        label='switch.portal.enquiry.service_location'
                        source="serviceLocationId"
                        reference="resources"
                        link={false}
                        queryOptions={{ staleTime: 5 * 60 * 1000 }}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <DateField source="bookingDate" label="switch.portal.enquiry.bookingdate" />
                    <FormattedTime source="startTime" label="switch.portal.enquiry.starttime" />
                    <FormattedTime source="endTime" label="switch.portal.enquiry.endtime" />
                    <TextField source="notes" label="switch.portal.enquiry.notes" />
                    <TextField source="guests" label="switch.portal.enquiry.guests" />
                    <NumberField source="lineItemCost" label='switch.portal.enquiry.cost' options={{
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2
                    }} />
                </Datagrid>
            </ArrayField>
        </>
    );
};
