import Button from '@mui/material/Button'
import { useRecordContext, useTranslate } from 'react-admin'
import { useRepeatBooking } from '../../Contexts/useRepeatBooking'

export const ExcludeRepeatBookingInstance = () => {
    const { ExcludeRepeatBooking, isLoading, IsDateExcluded } = useRepeatBooking()
    const record = useRecordContext()
    const t = useTranslate()

    if (!record) return;
    if (IsDateExcluded(record.bookingDate)) return;

    const handleClick = () => {
        ExcludeRepeatBooking(record.bookingDate)
    }

    return (
        <Button onClick={handleClick} disabled={isLoading}>{t("switch.portal.booking.views.repeat.cancel")}</Button>
    )
}