import GetAdjustedDate from '../../GetAdjustedDate';
import { EnquiryList } from './EnquiryList';


export const CurrentEnquiryList = () => {

    return (
        <EnquiryList filter={{ minDate: GetAdjustedDate(new Date()) }} />
    );
}

