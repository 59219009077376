import { Datagrid, DateField, List, NumberField, useTranslate, TextField, SimpleList } from 'react-admin';
import { useMediaQuery, useTheme } from '@mui/material';
import FormatDate from '../../components/FormatDate';
import { SimpleListRowStyle } from '../Shared/SimpleListRowStyle';
export const InvoiceList = () => {
    const translate = useTranslate();
    const theme = useTheme();
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    )

    return (
        <List
            exporter={false}
            queryOptions={{ staleTime: 5 * 60 * 1000 }}
            bulkActionButtons={false}
            sort={{ field: 'dueDate', order: 'DESC' }}
        >
            {isSmall ? (
                <SimpleList
                    rowSx={SimpleListRowStyle}
                    primaryText={record => record.switchURN}
                    secondaryText={record => translate('switch.portal.invoicelist.duedate') + FormatDate(record.dueDate)}
                    tertiaryText={record => translate('switch.portal.invoicelist.price') + `${record.gross}`}
                    linkType={'show'}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="switchURN" label={translate('switch.portal.invoicelist.reference')} />
                    <TextField source="customerRef" label={translate('switch.portal.invoicelist.customerref')} />
                    <TextField source="paymentLink" label={translate('switch.portal.invoicelist.paymentLink')} />
                    <TextField source="status" label={translate('switch.portal.invoicelist.status')} />
                    <DateField source="dueDate" label={translate('switch.portal.invoicelist.duedate')} />
                    <NumberField source="net" options={{
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2
                        }}
                            label={translate('switch.portal.invoicelist.net')} 
                        />
                    <NumberField source="tax" options={{
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2
                        }}
                            label={translate('switch.portal.invoicelist.tax')}
                        />
                    <NumberField source="gross" options={{
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2
                        }}
                            label={translate('switch.portal.invoicelist.gross')} />
                </Datagrid>
            )
            }
        </List>
    );
}