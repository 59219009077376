import { usePayment } from "../../../contexts/PaymentContext";

export const RenderHostedPaymentForm = () => {
    const { showIFrame, redirectURL } = usePayment()
    if (!showIFrame) return

    return (
        <iframe
            className="responsive-iframe"
            src={redirectURL}
            width="100%"
            height="500px"
        ></iframe>
    )
}