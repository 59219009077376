import React, { } from "react";
import {
    Show,
    ListButton,
    TopToolbar,
    SimpleShowLayout,
    TextField,
    NumberField,
    useTranslate,
    ReferenceField,
    useShowController,
} from 'react-admin';
import {
    Button,
} from '@mui/material';



const ShowActionBar = () => {
    const t = useTranslate()

    return (
        <TopToolbar sx={{ marginLeft: "auto", p: 2, borderRadius: 1 }}>
            <ListButton label={t('switch.portal.quote.show.backbutton')} />
        </TopToolbar>
    )
}

export const QuoteShow = () => {
    const t = useTranslate();
    const { record } = useShowController();

    const handleOpenUrl = (url) => {
        window.open(url, "_blank");
    };

    return (
        <>
            <Show
                actions={<ShowActionBar />}>
                <SimpleShowLayout>
                    <ReferenceField
                        label={t('switch.portal.quote.show.id')}
                        source="enquiryId"
                        reference="enquiry"
                        link={false}
                        queryOptions={{ staleTime: 5 * 60 * 1000 }}
                    >
                        <TextField source="switchURN" />
                    </ReferenceField>
                    <NumberField source="net"
                        label={t('switch.portal.quote.show.net')}
                        options={{
                            style: 'currency',
                            currency: 'GBP',
                            minimumFractionDigits: 2
                        }} />
                    <NumberField source="tax"
                        label={t('switch.portal.quote.show.tax')}
                        options={{
                            style: 'currency',
                            currency: 'GBP',
                            minimumFractionDigits: 2
                        }} />
                    <NumberField source="gross"
                        label={t('switch.portal.quote.show.gross')}
                        options={{
                            style: 'currency',
                            currency: 'GBP',
                            minimumFractionDigits: 2
                        }} />
                    <Button sx={{ mt: 1, mb: 1 }} onClick={() => handleOpenUrl(record.url)} variant="contained">{t('switch.portal.quote.show.docbtn')}</Button>
                </SimpleShowLayout>
            </Show>
        </>
    )
}