import React from 'react';
import {
    Show,
    TabbedShowLayout,
    ListButton,
    useTranslate
} from 'react-admin';
import {
    Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import { ContactInfo } from '../ContactInfo';
import { EnquiryResources } from '../EnquiryResources/EnquiryResources';
import { EnquirySummary } from '../EnquirySummary';

export const MobileShowView = () => {
    const t = useTranslate();

    return (
            <>
                <ListButton sx={{ marginLeft: "auto" }} />
                <Show>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary>
                            {t('switch.portal.enquiry.summary')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <EnquirySummary />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>
                            {t('switch.portal.enquiry.resources')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <EnquiryResources />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>
                            {t('switch.portal.enquiry.contact')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <ContactInfo />
                        </AccordionDetails>
                    </Accordion>
                </Show>
            </>
    );
};