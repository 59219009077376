import * as React from 'react';
import { TabbedShowLayout, usePermissions } from 'react-admin'
import MyAccountForm from '../MyAccountForm';
import { ShowCardsDesktop } from './ShowCardsDesktop';

export default function ShowMyAccountDesktop() {
    const { permissions } = usePermissions();

    const showCards = permissions?.showSavedCards;


    return (
        <TabbedShowLayout syncWithLocation={false}>
            <TabbedShowLayout.Tab label="switch.portal.myaccount.cards.general.title_account">
                <MyAccountForm />
            </TabbedShowLayout.Tab>
            {showCards && <TabbedShowLayout.Tab label="switch.portal.myaccount.cards.general.title_cards">
                <ShowCardsDesktop />
            </TabbedShowLayout.Tab>}
        </TabbedShowLayout>
    )

}