import React, { useEffect } from "react";
import {
    useMediaQuery,
    Box,
    Typography,
    CircularProgress
} from '@mui/material';
import ShowMyAccountMobile from "./Views/ShowMyAccountMobile";
import ShowMyAccountDesktop from "./Views/ShowMyAccountDesktop";
import { useMyAccount } from "../Contexts/useMyAccount";
import { useTranslate } from "react-admin";


const MyAccount = () => {
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    )

    const {
        account,
        recordLoading
    } = useMyAccount();
    const translate = useTranslate();


    if (!account || recordLoading) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight="100vh"
            >
                <CircularProgress size={64} />
                <Typography variant="h5">{translate('switch.portal.myaccount.profile.loading')}</Typography>
            </Box>
        );
    }


    return (
        <>
            {isSmall ? <ShowMyAccountMobile /> : <ShowMyAccountDesktop />}
        </>
    );
};

export default MyAccount;

