import { useState } from 'react'
import { Auth0AuthProvider, httpClient } from 'ra-auth-auth0';
import { useParams } from "react-router-dom";
import { auth0 } from './auth0';

const authProvider = (client) => {
    const [permissions, setPermissions] = useState()

    const prov = Auth0AuthProvider(auth0, {
    loginRedirectUri: window.location.origin + '/portal/' + client + '/auth-callback',
    logoutRedirectUri: window.location.origin + '/portal/' + client
    });
    return {
        ...prov,
        setPermissions,
        getPermissions: () => {
            return Promise.resolve(permissions)
        }
    }
}

export default authProvider;
