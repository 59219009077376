import { List, TextInput, TopToolbar, FilterButton, Button } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { BookingListMobile } from './listViews/BookingListMobile';
import { BookingListDesktop } from './listViews/BookingListDesktop';
import ReplayIcon from '@mui/icons-material/Replay';
import { useBookingList } from '../Contexts/useBookingList';

const BookingFilter = [
    <TextInput label="Search" source="search" resettable alwaysOn />
]


const ListActions = () => {
    const { SwitchCurrentView, switchLabel, showViewSwitch } = useBookingList();
    

    return (
        <TopToolbar>
            <FilterButton />
            {showViewSwitch && <Button label={switchLabel} onClick={SwitchCurrentView}>
                <ReplayIcon />
            </Button>}
        </TopToolbar>
    );
}


export const BookingList = (props) => {
    const { filter } = props;
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    )
    const { currentView } = useBookingList()
    
    return (
        <List
            filters={BookingFilter}
            filter={filter}
            sort={{ field: 'bookingDate', order: 'DESC' }}
            actions={<ListActions />}
            queryOptions={{ staleTime: 5 * 60 * 1000 }}
            resource={currentView}
            title={currentView === "booking" ? "switch.portal.booking.views.summary.title" : "switch.portal.booking.views.repeat.title"}
        >
            {isSmall ?  <BookingListMobile/>  : <BookingListDesktop/>}

        </List>
    );
}
