import React from 'react'
import {
  Box,
  Button,
} from "grommet";
import {formatISO, format} from 'date-fns'

export function bookingOrExtendCheck(newBooking, extend) {
  if (newBooking || extend) {return true}
  return false
}

const Footer = (props) => {
  const {stateColour, state, currentMeeting, setNewBooking, newBooking, setExtend, extend, setTab, setTSet} = props;

  return (
    <Box
      justify="center"
      align="center"
      background={stateColour}
      gridArea="footer"
    >
      <Box justify="evenly" direction="row">
              {state.canBook && <Button
                  margin="small"
                  size="large"
                  primary
                  label={bookingOrExtendCheck(newBooking, extend) ? "CANCEL" : "BOOK"}
                  onClick={() => {
                    if (!newBooking && !extend) {
                      var s = currentMeeting
                          ? format(new Date(), 'yyyy-MM-dd') +
                          " " +
                          currentMeeting.end
                          : new Date().toLocaleDateString("en-GB");
                      var start = !currentMeeting ? new Date() : new Date(s);

                      var end = new Date(start);
                      end.setHours(start.getHours() + 1);
                      setNewBooking({
                          description: "NEW BOOKING",
                          date: new Date().toISOString(),
                          bookingCode: "",
                          contactId: null,
                          start: start.toLocaleTimeString("en-GB"),
                          end: end.toLocaleTimeString("en-GB")
                      });
                  } else {
                    setExtend(null)
                    setNewBooking(null)
                    setTab(0)
                    setTSet(false)
                  }}
                
                }
              />}

        {/*<Button margin="small" size="large" primary label="SERVICE" />*/}

        {/*<Button margin="small" size="large" primary label="CLEAN" />*/}
      </Box>
    </Box>
  );
};

export default Footer