import React from 'react';
import {
  Box,
  Heading,
} from "grommet";
import { CustLogo } from "../../guestPortalComponents/CustLogo";


const Title = props => {
  const { title, screenType, abstractColour, displayDate, layout, logo } = props

  if (screenType === 'abstractLeft'){
    return(
      <Box direction="row" background={abstractColour ? abstractColour : 'brand'} align="right" pad="small" style={{zIndex: '1000'}} >
        <CustLogo 
        portalSettings={{ masterLogo: logo ? logo.imageUrl : process.env.PUBLIC_URL + "/image/transparent.png" }} 
        onHome={ ()=>{ return null } }
        />
        <Box direction="row" justify="between" fill>
          <Box alignSelf="center">
            <Heading level={title.level} color={title.colour}>{title.text}</Heading>
          </Box>
          <Box alignSelf="center">
            <Heading level={layout.dateBar.level} color={layout.dateBar.colour} >{displayDate.toDateString()}</Heading>
          </Box>
        </Box>
      </Box>
    )
  }
  return (
    <Box direction="row" align="center" pad="small">
      <Heading level={title.level} color={title.colour} >{title.text}</Heading>
        <CustLogo
            portalSettings={{ masterLogo: logo ? logo.imageUrl : process.env.PUBLIC_URL + "/image/transparent.png" }}
            onHome={ ()=>{ return null } }
        />
    </Box>
  );
};
export default Title;