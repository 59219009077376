import React from 'react'
import PlusMinusComponent from "./PlusMinusComponent"
import {
  Grid,
  Box,
} from "grommet";
import { add, sub, set } from 'date-fns'

const ExtendContents = (props) => {
  const {tab, extend, dateState, setDateState, endTimeState, setEndTimeState, tSet, setTSet} = props
  
  
  if (!tSet) {
    const initialStartSet = set(dateState, {hours: extend.start.substring(0,2), minutes: 0, seconds: 0})
    const initialEndSet = set(endTimeState, {hours: extend.end.substring(0,2), minutes: 0, seconds: 0})
    setDateState(initialStartSet)
    setEndTimeState(initialEndSet);
    setTSet(true);
  }


  function addButton(type) {
    let result
    if (tab < 1) {
      result = add(dateState, {
        [type]: type === "minutes" ? 15 : 1,
      })
      setDateState(result)
    } else {
      result = add(endTimeState, {
        [type]: type === "minutes" ? 15 : 1,
      })
      setEndTimeState(result)
    }
  }
  
  function subButton(type) {
    let result
    if (tab < 1) {
      result = sub(dateState, {
        [type]: type === "minutes" ? 15 : 1,
      })
      setDateState(result)
    } else {
      result = sub(endTimeState, {
        [type]: type === "minutes" ? 15 : 1,
      })
      setEndTimeState(result)
    }
  }
  const buttonsFn = { 
    addButton: addButton, 
    subButton: subButton
  }
  switch (tab) {
    case 0:
      return (
        <Grid
        rows={["small"]}
        columns={["20%", "20%"]}
        justifyContent="around"
        align="end"
        areas={[
          { name: 'hour', start: [0, 0], end: [0, 0] },
          { name: 'mins', start: [1, 0], end: [1, 0] },
        ]}
        fill
      >
        <Box gridArea="hour">
          <PlusMinusComponent type="hours" val={dateState.getHours()} buttonsFn={buttonsFn} />
        </Box>
        <Box gridArea="mins">
          <PlusMinusComponent type="minutes" val={dateState.getMinutes()} buttonsFn={buttonsFn} />
        </Box>
      </Grid>
      )
      
    case 1:
      return (
        <Grid
        rows={["small"]}
        columns={["20%", "20%"]}
        justifyContent="around"
        align="end"
        areas={[
          { name: 'hour', start: [0, 0], end: [0, 0] },
          { name: 'mins', start: [1, 0], end: [1, 0] },
        ]}
        fill
      >
        <Box gridArea="hour">
          <PlusMinusComponent type="hours" val={endTimeState.getHours()} buttonsFn={buttonsFn}/>
        </Box>
        <Box gridArea="mins">
          <PlusMinusComponent type="minutes" val={endTimeState.getMinutes()} buttonsFn={buttonsFn}/>
        </Box>
      </Grid>
      )
  
    default:
      return <Box><h1>Element Not Found!</h1></Box>
  }
};

export default ExtendContents;