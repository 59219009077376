import * as React from 'react';
import { useTranslate, usePermissions } from 'react-admin';
import CardWithIcon from './CardWithIcon';
import PaymentsIcon from '@mui/icons-material/Payments';

const PaymentsDue = (props) => {
    const { value } = props;
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (!permissions?.showPayments) return;

    return (
        <CardWithIcon
            to="Payments"
            icon={PaymentsIcon}
            title={translate('switch.portal.dashboard.paymentsdue')}
            subtitle={value}
        />
    );
};

export default PaymentsDue;