import React, { } from "react";
import {
    NumberField,
    ReferenceField,
    TextField,
    useTranslate,
    useRecordContext
} from 'react-admin';
import {
    Typography, Grid, Card
} from '@mui/material';
import FormatDate from "../../components/FormatDate";


export const BookingSummary = () => {
    const record = useRecordContext();
    const t = useTranslate()

    const showGuestNumber = (number) => {
        return number > 1 ? `${number} ${t('switch.portal.enquiry.guests')}` : `${number} ${t('switch.portal.enquiry.guest')}`
    }

    const formatTime = (timeString) => {
        const date = new Date(`1970-01-01T${timeString}`);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    return (
        <>
            <Card variant='outlined' sx={{ width: '100%', p: 2 }}>
                <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                    <Grid item xs={12}>
                        <TextField variant='h5' label={t('switch.portal.booking.views.summary.desc')} source="publicEventDescription" />
                    </Grid>
                    <Grid item xs={6}>
                        {showGuestNumber(record?.numberOfGuests)}
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceField
                            label="Venue"
                            source="venueId"
                            reference="venue"
                            link={false}
                            queryOptions={{ staleTime: 5 * 60 * 1000 }}
                        >
                            <TextField source="name" />
                        </ReferenceField>
                    </Grid>
                    <Grid item xs={6}>
                        {t('switch.portal.booking.views.summary.start_date')} {FormatDate(record?.bookingDate)}
                    </Grid>
                    <Grid item xs={6}>
                        {t('switch.portal.booking.views.summary.end_date')} {FormatDate(record?.bookingEndDate)}
                    </Grid>
                    <Grid item xs={6}>
                        {t('switch.portal.booking.views.summary.start')} {formatTime(record.startTime)}
                    </Grid>
                    <Grid item xs={6}>
                        {t('switch.portal.booking.views.summary.end')} {formatTime(record.endTime)}
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} sx={{ pt: 3 }}>
                        <Typography variant='h5'>{t('switch.portal.booking.views.summary.pricingsummary')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {t('switch.portal.booking.views.summary.price')}
                        <NumberField source="grossPrice" label='Price' options={{
                            style: 'currency',
                            currency: 'GBP',
                            minimumFractionDigits: 2
                        }} />
                    </Grid>
                    <Grid item xs={12}>
                        {t('switch.portal.booking.views.summary.outstandingbalance')}
                        <NumberField source="outstandingBalance" label='Price' options={{
                            style: 'currency',
                            currency: 'GBP',
                            minimumFractionDigits: 2
                        }} />
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}