import React, {useContext} from 'react'
import {
  Box,
  Heading,
  Accordion,
  AccordionPanel,
  ResponsiveContext
} from "grommet";
import { ScreenSizeToNum } from './functions';
import SideBarBookingButtons from './SideBarBookingButtons';

const SideBar = (props) => {
  const {stateColour, meetingView, setMeetingView, agenda, currentMeeting, setExtend, endCurrentMeeting, startMeeting, cancelBooking, state} = props;
  const { sideBar, bookingTime } = state.layout;
  const ScreenSizeNum = ScreenSizeToNum(useContext(ResponsiveContext))
  return (
    <Box gridArea="sidebar">
      <Box justify="center" align="center" background={stateColour}>
        <Heading level={sideBar.level} color={sideBar.colour} fill>
          TODAY
        </Heading>
      </Box>
      <Accordion
        activeIndex={meetingView}
        onActive={index => setMeetingView(index)}
      >
        {agenda.map((booking, index) => (
          <AccordionPanel label={booking.description}>
            <Box
              justify="center"
              align="center"
              border={{ color: { stateColour }, size: "large" }}
            >
              <Heading level={bookingTime.level} color={bookingTime.colour}>
                {booking.start} till {booking.end}
              </Heading>
              <SideBarBookingButtons booking={booking} currentMeeting={currentMeeting} setExtend={setExtend} endCurrentMeeting={endCurrentMeeting}
                                      startMeeting={startMeeting} index={index} cancelBooking={cancelBooking} ScreenSizeNum={ScreenSizeNum} />
            </Box>
          </AccordionPanel>
        ))}
      </Accordion>
    </Box>
  );
};

export default SideBar;