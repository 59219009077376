import React from 'react';
import {
    Show,
    TabbedShowLayout,
    ListButton,
    useTranslate,
} from 'react-admin';
import { ContactInfo } from '../ContactInfo';
import { EnquiryResources } from '../EnquiryResources/EnquiryResources';
import { EnquirySummary } from '../EnquirySummary';

export const DesktopShowView = () => {
    const t = useTranslate();

    return (
        <>
            <ListButton label={t('switch.portal.enquiry.backbutton')} sx={{ marginLeft: "auto" }} />
            <Show>
                <TabbedShowLayout>
                    <TabbedShowLayout.Tab label={t('switch.portal.enquiry.summary')}>
                        <EnquirySummary />
                    </TabbedShowLayout.Tab>
                    <TabbedShowLayout.Tab label={t('switch.portal.enquiry.resources')}>
                        <EnquiryResources />
                    </TabbedShowLayout.Tab>
                    <TabbedShowLayout.Tab label={t('switch.portal.enquiry.contact')}>
                        <ContactInfo />
                    </TabbedShowLayout.Tab>
                </TabbedShowLayout>
            </Show>

        </>

    );
};