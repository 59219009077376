import React, { } from "react";
import {
       useMediaQuery
} from '@mui/material';
import { BookingResourcesMobile } from "./resourcesViews/BookingResourcesMobile";
import { BookingResourcesDesktop } from "./resourcesViews/BookingResourcesDesktop";

export const BookingResources = () => {
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    )
    return (
        <>
            { isSmall ? <BookingResourcesMobile/> : <BookingResourcesDesktop/>}
        </>
    )
}