import { Auth0Client } from '@auth0/auth0-spa-js';


export const auth0 = new Auth0Client({
    domain: 'dispace.auth0.com',
    clientId: '5doc7jNeXCALkKBlhmj0jaD4m4bM4LpF',
    cacheLocation: 'localstorage',
    authorizationParams: {
        audience: 'https://switch.dispace.co/api/',
    },
});
