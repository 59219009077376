import React from 'react';
import {
    useMediaQuery
} from '@mui/material';

import { ResourcesDesktopView } from './ResourcesDesktopView';
import { ResourcesMobileView } from './ResourcesMobileView';

export const EnquiryResources = () => {
    const isSmall = useMediaQuery(
        (theme) => theme.breakpoints.down('sm'),
        { noSsr: true }
    );

    return (
        <>
            {isSmall ? <ResourcesMobileView/> : <ResourcesDesktopView/>}
        </>
    );
};
