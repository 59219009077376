import React, { useEffect } from "react";
import {
    Stack,
    Box
} from '@mui/material';



export const GenericMobileListViews = ({ children }) => {

    return (
        <Box sx={{ height: 500, overflowY: "auto", width:"100%" }}>
            <Stack direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}>

                {children}

            </Stack>

        </Box>
    );
};



