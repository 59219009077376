import React, { useState, useContext } from 'react'
import {
  Box,
  Heading,
  Grid,
  Button,
  Text,
  ResponsiveContext
} from "grommet";
import NewBookingContent from './NewBookingContent';
import Titles from './Titles'
import {SaveButtonCheck, KeyPadCheck, nextDisabledCheck, PinCheck, ScreenSizeToNum} from './functions'
import Keypad from './Keypad';
import ExtendContents from './ExtendContents'
import { Dialog } from '../../guestPortalComponents/Dialog'

const UnAvailableDialog = (props) => {
  const {unAvailable, setTab, setUnAvailable} = props;

  function reset() {
    setTab(2);
    setUnAvailable(null)
  }

  return (
    <Dialog title="Not Available" onCancel={() => reset()}>
      <Text>{unAvailable.unAvailableResources[0].key}</Text>
    </Dialog>
  );
};

const RoomStatus = (props) => {
  const { stateColour, agenda, currentMeeting, state, setNewBooking, saveBooking, newBooking, extendMeeting, extend, tab, setTab, api, unAvailable, setUnAvailable} = props;
  const { roomStatusLevel, roomStatusDetailLevel } = state.layout
  const [pinCheck, setPinCheck] = useState(null)
  const ScreenSizeNum = ScreenSizeToNum(useContext(ResponsiveContext))

  const RowsWithKeypad = {
    xsmall: ['10%', '15%', '15%', '60%'],
    medium: ['10%', '30%', '60%'],
  }
  const RowsWithoutKeypad = {
    xsmall: ['10%', '20%', '60%', '10%'],
    medium: ['10%', '70%', '10%'],
  }
  const Columns = {
    xsmall: ['25%','25%','25%','25%'],
    medium: ['22%', '55%', '22%'],
  }
  const areas = {
    xsmall: [
            { name: 'title', start: [0, 0], end: [3, 0] },
            { name: 'prev', start: [0, 1], end: [1, 1] },
            { name: 'next', start: [2, 1], end: [3, 1] },
            { name: 'formElements', start: [0, 2], end: [3, 2] },
            { name: 'keypad', start: [0, 3], end: [3,3] },
    ],
    medium: [
            { name: 'title', start: [0, 0], end: [2, 0] },
            { name: 'prev', start: [0, 1], end: [0, 1] },
            { name: 'formElements', start: [1, 1], end: [1, 1] },
            { name: 'next', start: [2, 1], end: [2, 1] },
            { name: 'keypad', start: [0, 2], end: [2,2] },
    ]
  
  }

  function tabSwitch(type) {
    switch (type) {
      case "next":

          // Check bookingPin is valid for Add Booking
          if (tab === 0 && newBooking) {
            PinCheck(api, newBooking, setPinCheck, setNewBooking)
          }
          
          // Normally add one to the current tab
          setTab(tab+1);
        break;
      case "prev":

        // Reset pinCheck if going back from error/success tab
        if (tab === 1) {setPinCheck(null)}

        // Normally subtract one to the current tab
        setTab(tab-1);
        break;
    
      default:
        break;
    }
  } 

  // Function for handling keypad buttons
  function keyPress(key) {
    // Key pressed is E(EnterIcon) and not last tab
    if (key === "E" && tab < 4) {
      // Check booking pin is valid if first tab
      if (tab === 0) {
        PinCheck(api, newBooking, setPinCheck, setNewBooking)
      }

      // Move to next tab
      setTab(tab+1) 
      return null
    }

    // Handle other keypresses
    let newValue
    if (key === "B") {
      // B(Backspace Icon) key pressed 
      newValue = newBooking.bookingCode.substring(0, newBooking.bookingCode.length - 1);
    } else {
      // All other numerical keys
      newValue = newBooking.bookingCode.toString() + key.toString()
    }
    setNewBooking({ ...newBooking, bookingCode: newValue })
  }

  const Content = () => {
    // Display Vacant / Occupied information if not cuttently booking ot extending
    if (!newBooking && !extend) {
      return (
        <Box justify="center" align="center">
          <Heading level={roomStatusLevel} color={stateColour}>
            {!currentMeeting ? "VACANT" : "OCCUPIED"}
          </Heading>
          {!currentMeeting ? null : (
            <Heading level={roomStatusDetailLevel} color={stateColour} fill>
              {currentMeeting.description}
            </Heading>
          )}
          {agenda && agenda.length > 0 ? (
            <Heading level={roomStatusDetailLevel} color={stateColour} fill>
              until {!currentMeeting ? agenda[0].start : currentMeeting.end}
            </Heading>
          ) : null}
        </Box>
      )
    }

      return(
        <Grid
          rows={
            ((ScreenSizeNum < 2) ? 
              // if screen size IS small
              ((tab === 0 && newBooking) ? /* If it IS keypad Tab */ RowsWithKeypad.xsmall : /* If its NOT keypad Tab */ RowsWithoutKeypad.xsmall) 
            : 
              // If its NOT small
              ((tab === 0 && newBooking) ? /* If it IS keypad Tab */ RowsWithKeypad.medium : /* If its NOT keypad Tab */ RowsWithoutKeypad.medium) 
            )
            }
          columns={(ScreenSizeNum < 2) ? Columns.xsmall : Columns.medium}
          justifyContent="around"
          areas={(ScreenSizeNum < 2) ? areas.xsmall : areas.medium}
          fill
        >
          <Box gridArea="title" align="center" alignSelf="center">
            <Heading level={2}>
              <Titles tab={tab} newBooking={newBooking} />
            </Heading>
          </Box>
          <Box gridArea="prev" align="center" alignSelf="center">
              <Button 
                primary 
                size="large"
                label={(ScreenSizeNum < 2) ? "Prev" : "Previous"}
                a11yTitle="Previous Step"
                disabled={(tab === 0) && true}
                onClick={() => {tabSwitch("prev")}} />
          </Box>
          <Box gridArea="formElements" align="center" alignSelf="center">
            {extend ? 
              <ExtendContents tab={tab} {...props} /> 
            :
              <NewBookingContent tab={tab} pinCheck={pinCheck} {...props} />
            }
          </Box>
          <Box gridArea="next" align="center" alignSelf="center">
            <Button 
                primary 
                size="large"
                label="Next"
                a11yTitle="Next Step"
                disabled={nextDisabledCheck(tab, pinCheck, extend)}
                onClick={() => {tabSwitch("next")}} />
          </Box>
          
          <Box gridArea="keypad">
          {KeyPadCheck(tab, extend) &&
            <Keypad keyPress={keyPress} />
          }
          {SaveButtonCheck(tab, extend, newBooking) && 
            <Button 
              primary 
              size="large"
              margin="10px 100px"
              label={!extend ? "Save Booking" : "Save"}
              onClick={!extend ? saveBooking : extendMeeting} 
            />
          }
          </Box>
          {props.unAvailable && 
            <UnAvailableDialog unAvailable={unAvailable} setUnAvailable={setUnAvailable} setTab={setTab} />
          }
        </Grid>
      )
  }

  return (
    <Box
      justify="center"
      background="light-2"
      gridArea="main"
      height={{ min: "530px" }}
    >

      <Content />
    </Box>
  );
};

export default RoomStatus