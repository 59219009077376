import React from "react";
import {
    useStripe,
    useElements,
    CardCvcElement
} from "@stripe/react-stripe-js";
import Typography from '@material-ui/core/Typography';
import { usePayment } from "./contexts/PaymentContext";
import { useBookingPayment } from "./ClientPortal/Contexts/useBookingPayment";


const CVCCaptureForm = (props) => {
    const { handleNext , label } = props;
    const stripe = useStripe();
    const elements = useElements();
    const { secret, selectedCard, SetError, paymentCompleted, setPaymentStatus, setReady, stripeStyleOptions } = usePayment();

    const bookingPaymentContext = useBookingPayment()
    let stripeSecret
    if (!secret) {
        stripeSecret = bookingPaymentContext?.secret
    } else {
        stripeSecret = secret

    }

    const handleSubmit = async event => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setPaymentStatus("processing");
        const result = await stripe.confirmCardPayment(stripeSecret, {
            payment_method: selectedCard.stripeId,
            payment_method_options: {
                card: {
                    cvc: elements.getElement(CardCvcElement)
                }
            },     
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
            SetError(result.error.message);
            setPaymentStatus("error");
        } else {
            paymentCompleted(result.paymentIntent);
            setPaymentStatus("succeeded");
            if (handleNext != null) {
                handleNext();
            }
        }
    };


    if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return (<div/>);
    }

    return (
        <div>
            <form id="cvcRecapture" onSubmit={handleSubmit}>
                <Typography style={{ fontWeight: 600 }} variant="body2" sx={{ width: '33%', flexShrink: 0 }}>{label}</Typography>
                <CardCvcElement options={stripeStyleOptions} onReady={() => setReady(true)} />
            </form>
        </div>
    );
};

export default CVCCaptureForm;
