  //function for checking Bookign Pin
  export function PinCheck(api, newBooking, setPinCheck, setNewBooking) {
    setPinCheck("checking");
    api.CheckBookingPin(newBooking.bookingCode).then(result => {
      if (result !== null && result !== undefined) {
        setPinCheck({
          ...result
        });
        setNewBooking({...newBooking, 
          description: result.name + " - NEW BOOKING",
          contactId: result.id })
      }
    });
  }

// Function to check if they keypad needs to be displayed
export function KeyPadCheck(tab, extend) {
  if (!extend && tab === 0){ return true}
  return false
}

// Function to check if the next button should be disabled
export function nextDisabledCheck(tab, pinCheck, extend) {
  if (tab === 4) {
    return true
  }
  if (pinCheck && pinCheck.status) {
    return true
  }
  if (extend && tab === 1) {
    return true
  }
  return false
}

// function to check if the save button should be displayed
export function SaveButtonCheck(tab, extend, newBooking) {
  // Is a new booking and last tab
  if (!extend && tab === 4) {return true}
  // Is extend and last tab
  if (!newBooking && tab === 1) {return true}
  return false
}

export function ScreenSizeToNum(screenSize) {
  switch (screenSize) {
    case "xsmall":
      return 0;
      break;
    case "small":
      return 1;
      break
    case "medium":
      return 2;
      break
    case "large":
      return 3;
      break
    case "xlarge":
      return 4;
      break
    default:
      return 3;
      break;
  }
}