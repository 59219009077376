import {  List,TextInput} from 'react-admin';
import { ListActionBar } from '../../components/ListActionBar';
import { useMediaQuery } from '@mui/material';
import { MobileListView } from './ListViews/MobileListView';
import { DesktopListView } from './ListViews/DesktopListView';

const EnquiryFilter = [
    <TextInput label="Search" source="search" resettable alwaysOn />
]

export const EnquiryList = (props) => {
    const { filter } = props;
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    )

    return (
        <List
            bulkActionButtons={false}
            filters={EnquiryFilter}
            filter={filter}
            sort={{ field: 'bookingDate', order: 'DESC' }}
            actions={<ListActionBar />}
            queryOptions={{ staleTime: 5 * 60 * 1000 }}
        >
            {isSmall ? <MobileListView/> : <DesktopListView/>}
        </List>
    );
}