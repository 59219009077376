import { InfiniteList, Datagrid, TextField, DateField, BooleanField, InfinitePagination, FunctionField, BulkDeleteButton, usePermissions } from 'react-admin';
import { EditCardDialog } from '../EditDialogs/EditCardDialog';

export const ShowCardsDesktop = () => {
    const { permissions } = usePermissions();

    const allowDelete = permissions?.allowSavedCardDelete;

    return (
        <InfiniteList resource="Card" sort={{ field: 'expiry', order: 'DESC' }} exporter={false}>
            <Datagrid bulkActionButtons={!allowDelete ? false : <BulkDeleteButton/> }>
                <FunctionField label="switch.portal.myaccount.cards.general.brand"
                    render={record => (<img key={record?.id} src={`./image/cards/${record?.brand}.png`} alt={record.brand} width="50px" align="bottom" style={{ padding: "0px 10px" }} />)
                    } />
                <TextField source="last4" label="switch.portal.myaccount.cards.general.last4" />
                <DateField source="expiry" label="switch.portal.myaccount.cards.desktop.expiry" />
                <BooleanField source="consentToUse" label="switch.portal.myaccount.cards.desktop.consent" />
                <BooleanField source="default" label="switch.portal.myaccount.cards.desktop.default" />
                <EditCardDialog />
            </Datagrid>
            <InfinitePagination />
        </InfiniteList>
    );
};

