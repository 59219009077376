import {
    Datagrid,
    DateField,
    NumberField,
    ReferenceField,
    TextField
} from 'react-admin';
import FormattedTime from '../../Shared/FormattedTime';


export const BookingListDesktop = () => {

    return (
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField label='switch.portal.bookingshow.lists.desktop.desc' source="publicEventDescription" />
            <TextField source="switchURN" label='switch.portal.bookingshow.urn' />
            <ReferenceField
                label='switch.portal.bookingshow.venue' 
                source="venueId"
                reference="venue"
                link={false}
                queryOptions={{ staleTime: 5 * 60 * 1000 }}
            >
                <TextField source="name" />
            </ReferenceField>
            <TextField source="bookingStatus" label='switch.portal.bookingshow.status' />
            <DateField source="bookingDate" locales="en-GB" label='switch.portal.bookingshow.startdate' />
            <DateField source="bookingEndDate" locales="en-GB" label='switch.portal.bookingshow.enddate' />
            <FormattedTime source="startTime" label='switch.portal.bookingshow.starttime' />
            <FormattedTime source="endTime" label='switch.portal.bookingshow.endtime' />
            <NumberField source="numberOfGuests" label='switch.portal.bookingshow.guests' />
            <NumberField source="outstandingBalance" label='switch.portal.bookingshow.lists.desktop.balance' options={{
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2
            }} />
        </Datagrid>
    );
}