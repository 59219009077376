import React, { useState } from 'react';
import MyAccountForm from '../MyAccountForm';
import { ShowCardsMobile } from './ShowCardsMobile';
import {
    useTranslate,
    usePermissions
} from "react-admin";
import { AccordionForm } from '@react-admin/ra-form-layout';

export default function ShowMyAccountMobile() {
    const t = useTranslate();
    const [cardCount, setCardCount] = useState(0);
    const { permissions } = usePermissions();

    const showCards = permissions?.showSavedCards;

    const cardlabel = t("switch.portal.myaccount.cards.general.title_cards") + " (" + cardCount + ")"

    return (
        <AccordionForm autoClose toolbar={false}>
            <AccordionForm.Panel label="switch.portal.myaccount.cards.general.title_account">
                <MyAccountForm />
            </AccordionForm.Panel>
            {showCards && <AccordionForm.Panel label={cardlabel}>
                <ShowCardsMobile setCardCount={setCardCount} />
            </AccordionForm.Panel>}
        </AccordionForm>

    )
}