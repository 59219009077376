import React, { } from "react";
import {
    SimpleList,
    useTranslate
} from 'react-admin';

import FormatDate from "../../../components/FormatDate";
import { SimpleListRowStyle } from "../../Shared/SimpleListRowStyle";

export const BookingListMobile = () => {
    const t = useTranslate();
    return (
        <SimpleList
            rowSx={SimpleListRowStyle}
            primaryText={record => record.publicEventDescription}
            secondaryText={record => FormatDate(record.bookingDate)}
            tertiaryText={record => `${t('switch.portal.bookingshow.lists.mobile.balance')}${record?.outstandingBalance ? record?.outstandingBalance.toFixed(2) : 0.00}`}
            linkType={'show'}
        />
    )
}