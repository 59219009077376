import React from 'react';
import { useTranslate } from 'react-admin';
import { Button, Typography, DialogContent, DialogActions, CircularProgress, Stack } from '@mui/material';
import { useBookingCancel } from '../../Contexts/useBookingCancel';

export const RefundAmount = () => {
    const { refundAmount, cancelBooking, CloseCancelDialog, loading } = useBookingCancel();
    const translate = useTranslate();

    return (
        <>
            <DialogContent>
                {loading ? <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                ><CircularProgress /> </Stack> : <>
                    <Typography align='center'>{translate('switch.portal.booking.cancel.confirm')}</Typography>
                    <Typography align='center'> {translate('switch.portal.booking.cancel.refundamount')} {String.fromCharCode(163)}{refundAmount} </Typography>
                </>}
            </DialogContent>
            <DialogActions>
                <Button sx={{ m: 1 }} onClick={cancelBooking} disabled={loading}>
                    {translate('switch.portal.booking.cancel.yes')}
                </Button>
                <Button sx={{ m: 1 }} onClick={CloseCancelDialog}>
                    {translate('switch.portal.booking.cancel.no')}
                </Button>
            </DialogActions>
        </>
    );

};
