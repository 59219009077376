import React, { useEffect, useState } from "react";
import { useTranslate } from 'react-admin';
import { Typography } from "@mui/material";
import { usePayment } from "../../../contexts/PaymentContext";
import { RenderStripePaymentForm } from "./RenderStripePaymentForm";
import { PaymentSuccess } from "./PaymentSuccess";
import { usePortalManagePayment } from "../../../contexts/PortalManagePaymentContext";
import { useBookingPayment } from "../../Contexts/useBookingPayment";
export default function PaymentCheckoutForm(props) {
    const translate = useTranslate()
    const portalContext = usePortalManagePayment();
    const SaveBookingWithPendingPayment = portalContext?.SaveBookingWithPendingPayment;

    const {
        stripeOptions,
        loading,
        paymentStatus
    } = usePayment();

    const bookingPaymentContext = useBookingPayment()

    let stripeDetails = {}

    if (bookingPaymentContext) {
        stripeDetails = {
            ...bookingPaymentContext.stripeDetails
        }
    } else {
        stripeDetails = {
            ...stripeOptions
        }
    }


    const Preparing = () => (<Typography align='center' sx={{ p: 2 }}>{translate('switch.portal.paymentslist.preparingpaymentdata')}</Typography>)


    const renderForm = () => {
        if (loading || !stripeDetails.clientSecret) return <Preparing />;
        return (
            <RenderStripePaymentForm />
        );
    };


    return (
        paymentStatus == "succeeded" ? <PaymentSuccess onSuccess={SaveBookingWithPendingPayment} /> : renderForm()
    );
}
