import React, { } from "react";
import {
    useTranslate,
    useRecordContext,
} from 'react-admin';
import { BookingSummary } from "../BookingSummary";
import { BookingContact } from "../BookingContact";
import { BookingResources } from "../BookingResources";
import { AccordionForm } from '@react-admin/ra-form-layout';

export const BookingShowMobile = () => {
    const record = useRecordContext();
    const t = useTranslate()
    if (!record) return null

    return (
        <AccordionForm autoClose toolbar={false}>
            <AccordionForm.Panel label={t('switch.portal.booking.views.summary.summary')}>
                <BookingSummary />
            </AccordionForm.Panel>
            <AccordionForm.Panel label={t('switch.portal.booking.views.resource.general.resource')}>
                <BookingResources />
            </AccordionForm.Panel>
            <AccordionForm.Panel label={t('switch.portal.booking.views.contact.contact')}>
                <BookingContact />
            </AccordionForm.Panel >
        </AccordionForm>
    )
}