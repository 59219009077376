import React, { useEffect, useState } from "react";
import { Box, Typography, FormControlLabel, Checkbox, FormControl, RadioGroup, Radio } from "@mui/material";
import { usePayment } from "../../../contexts/PaymentContext";
import { useTranslate } from 'react-admin';

export const WindCaveCardsCheck = () => {
    const translate = useTranslate()
    const {
        consent,
        ChangeConsent,
        saveDefault,
        ChangeDefault,
        savedCards,
        handleChangeCard,
        SetSelectedCard,
        SetCardType,
        cardType
    } = usePayment();

    const [saveNewCard, ChangeNewCard] = useState(cardType === "newcard");


    function handleNewCard(event) {
        SetCardType(event.target.checked ? "newcard" : "saved");
        ChangeNewCard(event.target.checked);
        if (event.target.checked) SetSelectedCard(null);
    }

    return (
        <>
            <Typography>{translate('switch.portal.paymentslist.windcavecardselect')}</Typography>

            {savedCards?.length > 0 && <Box>
                <FormControl>
                    <RadioGroup
                        onChange={handleChangeCard}
                    >
                        {savedCards.map((card) => {
                            const cardExpiryDate = new Date(card.expiry)
                            const expiryMonth = new Intl.DateTimeFormat('en-GB', { month: '2-digit' }).format(cardExpiryDate);
                            const expiryDate = `${expiryMonth}/${cardExpiryDate.getFullYear()}`
                            return <FormControlLabel label={`*** ${card.last4} - Expires ${expiryDate}`} control={<Radio value={card.id} />} />

                        })}
                    </RadioGroup>
                </FormControl>
            </Box>}

            <Box sx={{ pt: 1 }}>
                <FormControlLabel
                    control={<Checkbox
                        checked={consent}
                        onChange={ChangeConsent}
                    />}
                    label={translate('switch.portal.paymentslist.futurepayments')}
                />
            </Box>
            <Box>
                <FormControlLabel
                    control={<Checkbox
                        checked={saveDefault}
                        onChange={ChangeDefault}
                    />}
                    label={translate('switch.portal.paymentslist.defaultcard')}
                />
            </Box>
            <Box>
                <FormControlLabel
                    control={<Checkbox
                        checked={saveNewCard}
                        onChange={handleNewCard}
                    />}
                    label={savedCards?.length > 0 ? translate('switch.portal.paymentslist.differentcard') : translate('switch.portal.paymentslist.newcard')}
                />

            </Box>
        </>
    );
}