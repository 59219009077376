import * as React from "react";
import CardContent from "@mui/material/CardContent";
import { Title } from "react-admin";
import { usePortal } from "../Contexts/usePortal";
import { Box, Card, CardActions, Button, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';


const Welcome = () => {
    const translate = useTranslate();
    const { settings } = usePortal()
    return (
        <Card
            sx={{
                background: theme =>
                    `linear-gradient(45deg, ${theme.palette.secondary.dark} 0%, ${theme.palette.secondary.light} 50%, ${theme.palette.primary.dark} 100%)`,
                color: theme => theme.palette.primary.contrastText,
                padding: '20px',
                marginTop: 2,
                marginBottom: '1em',
            }}
        >
            <Title title={translate('switch.portal.dashboard.title')}/>
            <Box display="flex">
                <Box flex="1">
                    <Typography variant="h5" component="h2" gutterBottom>
                        {translate('switch.portal.dashboard.welcome.title')}
                    </Typography>
                    <Box maxWidth="40em">
                        <Typography variant="body1" component="p" gutterBottom>
                            {translate('switch.portal.dashboard.welcome.subtitle')}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    display={{ xs: 'none', sm: 'block', md: 'block' }}
                    sx={{
                        background: `url(${settings?.theme?.logoImage?.imageUrl}) top right / contain no-repeat`,
                        marginLeft: 'auto',
                    }}
                    width="16em"
                    height="9em"
                    overflow="hidden"
                />
            </Box>
        </Card>
    );
};

export default Welcome;