import React, {} from 'react';
import {
    Loading,
    usePermissions} from 'react-admin';
import { Dialog } from '@mui/material';
import { useBookingCancel } from '../../Contexts/useBookingCancel';
import { RefundAmount } from './RefundAmount';
import { CancellationReason } from './CancellationReason';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@mui/material/styles';
export const CancelBookingDialog = () => {
    const { cancelDialogIsOpen, cancellationReason } = useBookingCancel();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { permissions } = usePermissions();

    if (!permissions?.allowBookingCancel) return;

    return (
        <Dialog
            open={cancelDialogIsOpen}
            fullScreen={fullScreen}
        >
            {!cancellationReason ? <CancellationReason /> : <RefundAmount />}
        </Dialog>
    )
}

