import { useEffect } from 'react';
import {
    useListContext,
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
} from "react-admin";
import { useTheme } from '@mui/material/styles';

export const ListActionBar = () => {
    const theme = useTheme()
    const listContext = useListContext();
    const { filterValues } = listContext;

    return (
        <TopToolbar>
            <FilterButton sx={{
                '& .MuiButton-root': {
                    color: theme.palette.actionButtons,
                },
            }}
            />
        </TopToolbar>
    );
};
