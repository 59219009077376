import React from "react";
import { Box, Heading } from "grommet";

const MainImageWithText = props => {
  const { imageUrl, title, line1, line2 } = props;

  return (
    <Box
      justify="stretch"
      alignContent="stretch"
      fill={true}
      align="center"
      pad="medium"
      background={{
        position: "center",
        repeat: "no-repeat",
        size: "cover",
        image: "url(" + imageUrl + ")"
      }}
    >
      <Box direction="column" fill="horizontal" align="center" justify="center" height="large">
        <Heading pad="small" level={title ? title.level : 1} color={title ? title.colour : '#000000'}>
          {title ? title.text : ''}
        </Heading>
        <Heading level={line1 ? line1.level : 1} color={line1 ? line1.colour : '#000000'} pad="small">
          {line1 ? line1.text : ''}
        </Heading>
        <Heading level={line2 ? line2.level : 1} color={line2 ? line2.colour : '#000000'} pad="small">
          {line2 ? line2.text : ''}
        </Heading>
      </Box>
    </Box>
  );
};

export default MainImageWithText;
