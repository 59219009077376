import { httpClient } from './httpClient';
import jsonServerProvider from 'ra-data-json-server'
import Api from './portalApi'

export const dataProvider = (provider, clientName) => {
    const API_URL = '/Dispace/Switch/Portal/1.0.0'
    const client = httpClient(provider, clientName)
    const dp = jsonServerProvider(API_URL, client)

    const API = new Api({
        urlRoot: window.location.origin + API_URL,
        client: client
    })

    return {
        ...dp,

        update: (resource, params) => {
            switch (resource) {
                case "booking":
                    switch (params.data?.api) {
                        case "cancel":
                            let url = API_URL + '/booking/' + params?.id + '/cancel?cancelReason=' + params.data.cancellationReason
                            return client(url, { method: 'PUT', body: JSON.stringify({ message: 'cancel booking' }) }).then(({ json }) => ({ data: json }));
                    }
                case "repeatbooking":
                    switch (params.data?.api) {
                        case "exclude":
                            let url = API_URL + '/repeatbooking/' + params?.id + '/exclude?date=' + params.data.bookingDate
                            return client(url, { method: 'PUT' }).then(({ json }) => ({ data: json }));
                    }
                default:
                    return dp.update(resource, params)
            }
        },
        CalculateRefund: (bookingId, bookingData) => { return API.calculateRefund(bookingId, bookingData) },
        setupPayment: (booking, saveCard, amount, response, paymentId, selectedCard) => { return API.setupPayment(booking, saveCard, amount, response, paymentId, selectedCard) },
        saveCardPreferences: (contactID, cardId, saveDefault, consent, stripeRef, provider) => { return API.saveCardPreferences(contactID, cardId, saveDefault, consent, stripeRef, provider) },
        getPaymentStatus: (bookingId, paymentId) => { return API.getPaymentStatus(bookingId, paymentId) },
        MonitorPayment: (paymentId) => { return API.MonitorPayment(paymentId) },
    }
}



