import * as React from "react";
import { Loading, Authenticated, useAuthenticated } from "react-admin";
import { usePortal } from "../Contexts/usePortal";
import CurrentBookings from "./CurrentBookings";
import CurrentQuotes from "./CurrentQuotes";
import PaymentsDue from "./PaymentsDue";
import { useMediaQuery } from '@mui/material';
import Welcome from "./Welcome";
import CurrentEnquires from "./CurrentEnquires";
import NewBookingLink from "./NewBookingLink";
import Stack from '@mui/material/Stack';

export default () => {
    useAuthenticated();
    const { widgetKey, settings, isLoading } = usePortal()

    const isSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('lg')
    );


    if (isLoading || !settings) return (<Loading />);

    const { currentBookings, currentEnquires, currentQuotes, paymentsDue } = settings;

    const styles = {
        flex: { display: 'flex' },
        flexColumn: { display: 'flex', flexDirection: 'column' },
    };

    const Spacer = () => <span style={{ width: '1em' }} />;
    const VerticalSpacer = () => <span style={{ height: '1em' }} />;

    return isSmall ? (
        <Authenticated>
            <div style={styles.flexColumn}>
                <Welcome />
                <VerticalSpacer />
                <CurrentBookings value={currentBookings.toString()} />
                <VerticalSpacer />
                <CurrentEnquires value={currentEnquires.toString()} />
                <VerticalSpacer />
                <CurrentQuotes value={currentQuotes.toString()} />
                <VerticalSpacer />
                <PaymentsDue value={paymentsDue.toString()} />
                <VerticalSpacer />
                <NewBookingLink value={widgetKey} />
            </div>
        </Authenticated>
    ) : (<Authenticated>
        <Welcome />

        <div style={styles.flex}>
            <CurrentBookings value={currentBookings.toString()} />
            <Spacer />
            <CurrentEnquires value={currentEnquires.toString()} />
            <Spacer />
            <CurrentQuotes value={currentQuotes.toString()} />
            <Spacer />
            <PaymentsDue value={paymentsDue.toString()} />
            <VerticalSpacer />

        </div>
        <div style={styles.flexColumn}>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={2}
                sx={{ mt: 1, padding: 1 }}
            >
                <NewBookingLink value={widgetKey} />
            </Stack>
        </div>

    </Authenticated>
    );
}

