export const FontPicker = (font) => {
    switch (font) {
        case "Arial":
            return "Arial";
        case "Roboto":
            return "Roboto"
        case "Montserrat":
            return "Montserrat"
        case "Oswald":
            return "Oswald"
        case "Karla":
            return "Karla"
        case "Poppins":
            return "Poppins"
        case "Open Sans":
            return "Open Sans"
        case "Playfair Display":
            return "Playfair Display"
        case "Arial, sans-serif":
            return "Arial, sans-serif"
        case "Signika Negative":
            return "Signika Negative"
        case "Cinzel":
            return "Cinzel, serif"
        case "Big Caslon":
            return "big-caslon-fb,“Big Caslon”,“Book Antiqua”,Palatino,Georgia,serif"
        case "Source Sans 3":
            return "SourceSans3, sans-serif"
        case "Futura":
            return "futura-pt,Futura,Trebuchet MS,sans-serif"
        case "Raleway":
            return "Raleway"
        case "Helvetica":
            return "Helvetica Neue, Helvetica, Arial, sans-serif"
        case "Droid Serif":
            return "Droid Serif"
        default:
            return "Roboto"
    }
}