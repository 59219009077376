import React from 'react'
import {
  Box,
  Heading,
  Grid,
  Clock,
} from "grommet";


const HeaderTitleContent = (props) => {
  const {state, newBooking, extend} = props;

  if (newBooking) {
    return "Book This Room"
  } else if (extend) {
    return "Extend Booking"
  } else if (state.title) {
    return state.title.text
  }
    
  return "title.text Not Found"
};

const RoomHeader = (props) => {
  const {stateColour, state} = props;
  const {clock} = state.layout

  function ClockLevel() {
    switch (clock.level) {
      case 1:
        return "xxlarge"
      case 2:
        return "xlarge"
      case 3:
        return "large"
      case 4:
        return "medium"
      case 5:
        return "small"
      case 6:
        return "xsmall"
      default:
        return "xlarge"
    }
  }
  return (
      <Grid
        rows={["xsmall"]}
        columns={["1/4", "3/4"]}
        areas={[["time", "roomname"]]}
        // gap="small"
      >
        <Box
          justify="center"
          align="center"
          gridArea="time"
          border={{ color: { stateColour }, size: "large" }}
        >
          <Clock
            precision="minutes"
            type="digital"
            size={ClockLevel()}
            style={{color: stateColour}}
          />
        </Box>

        <Box
          justify="center"
          align="center"
          background={stateColour}
          gridArea="roomname"
        >
          <Heading level={state.title ? state.title.level : 1} color={state.title ? state.title.colour : '#444444'} fill>
            <HeaderTitleContent {...props} />
          </Heading>
        </Box>
      </Grid>
  );
};

export default RoomHeader;