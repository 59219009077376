import React, { } from "react";
import {
    List,
    TextInput,
    useTranslate
} from 'react-admin';
import {
    useMediaQuery,
} from '@mui/material';
import { ListActionBar } from '../../components/ListActionBar';
import { QuoteListMobile } from './views/QuoteListMobile';
import { QuoteListDesktop } from './views/QuoteListDesktop';


export const QuoteList = () => {
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    )

    const t = useTranslate()

    const QuoteFilter = [
        <TextInput label={t('switch.portal.quote.list.general.search')} source="search" resettable alwaysOn />
    ]
     
    return (
        <List
            bulkActionButtons={false}
            filters={QuoteFilter}
            sort={{ field: 'createdDate', order: 'DESC' }}
            actions={<ListActionBar />}
            queryOptions={{ staleTime: 5 * 60 * 1000 }}
        >
            {isSmall ? <QuoteListMobile/> : <QuoteListDesktop/>}
        </List>
    );
}
