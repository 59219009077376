import React, { } from "react";
import {
    useRecordContext,
    SimpleList,
    ArrayField,
    useTranslate,
    ResourceContextProvider
} from 'react-admin';
import { SimpleListRowStyle } from "../../Shared/SimpleListRowStyle";
import FormatDate from "../../../components/FormatDate";



export const RelatedBookingMobile = () => {
    const record = useRecordContext();
    if (!record) return null
    const t = useTranslate()

    return (
        <ArrayField source="relatedBookings">
            <ResourceContextProvider value="booking">
                <SimpleList
                    rowSx={SimpleListRowStyle}
                    primaryText={record => record?.switchURN}
                    secondaryText={record => FormatDate(record?.bookingDate)}
                    tertiaryText={record => `${t('switch.portal.bookingshow.lists.mobile.balance')}${record?.outstandingBalance ? record?.outstandingBalance.toFixed(2) : 0.00}`}
                    linkType="show"
                />
            </ResourceContextProvider>
        </ArrayField>

    )
}