import React from "react";
import {
    useTranslate,
    Form,
    BooleanInput,
    TextInput,
    SaveButton,
    EditBase,
    useNotify,
    usePermissions
} from "react-admin";
import {
    Stack,
    Grid,
    IconButton,
    Tooltip
} from "@mui/material";
import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { useMyAccount } from "../Contexts/useMyAccount";


const MyAccountForm = () => {
    const {
        account
    } = useMyAccount();
    const translate = useTranslate();
    const notify = useNotify()
    const { isLoading, permissions } = usePermissions();

    const saveDisabled = !permissions?.allowProfileEdit;

    const onSuccess = () => {
        notify(`switch.portal.myaccount.profile.saved`);
    };

    return (
        <EditBase
            id={account?.id}
            resource="account"
            mutationMode="optimistic"
            mutationOptions={{ onSuccess }}
        >
            <Form>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <TextInput
                        id="clientName"
                        label='switch.portal.myaccount.profile.name'
                        sx={{ mb: 2 }}
                        source="firstName"
                        disabled={saveDisabled}
                    />
                    <TextInput
                        id="clientLastName"
                        label='switch.portal.myaccount.profile.surname'
                        sx={{ mb: 2 }}
                        source="lastName"
                        disabled={saveDisabled}
                    />
                    <TextInput
                        id="clientEmail"
                        label='switch.portal.myaccount.profile.email'
                        sx={{ mb: 2 }}
                        source="email"
                        disabled
                    />
                    {account?.companyId && <TextInput
                        id="company"
                        label='switch.portal.myaccount.profile.company'
                        sx={{ mb: 2 }}
                        source="company.name"
                        disabled
                    />}
                    <TextInput
                        id="clientNumber"
                        label='switch.portal.myaccount.profile.mobile'
                        source="mobile"
                        disabled={saveDisabled}
                    />

                    <BooleanInput
                        sx={{ mt: 0.3 }}
                        data-testid="marketing-checkbox"
                        source="allowMarketing"
                        label='switch.portal.myaccount.profile.allowmarketing'
                        disabled={saveDisabled}                    />

                    <Grid container alignItems="center">
                        <Grid item>
                            <BooleanInput
                                sx={{ mt: 0.3 }}
                                data-testid="3rd-party-marketing-checkbox"
                                label='switch.portal.myaccount.profile.allow3rdpartymarketing'
                                source="allow3rdPartyMarketing"
                                disabled={saveDisabled}                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title={translate('switch.portal.myaccount.profile.3pMarketingTip')}>
                                <IconButton sx={{ mb: 0.2 }} size="small">
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <SaveButton label="switch.portal.myaccount.profile.update" />

                </Stack>

            </Form>
        </EditBase >

    );
};

export default MyAccountForm;