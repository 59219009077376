import {
    DateField,
    SimpleForm,
    BooleanInput,
    useTranslate,
    useRecordContext,
    usePermissions,
    Toolbar,
    SaveButton,
    DeleteButton

} from 'react-admin';
import { Grid, Typography, Stack } from '@mui/material';
import { EditDialog } from "@react-admin/ra-form-layout";
import { useMyAccount } from '../../Contexts/useMyAccount';

export const EditCardDialog = ({ card}) => {
    const t = useTranslate();
    const record = useRecordContext();
    const { permissions } = usePermissions();
    const { dialogState, currentCard, Open, Close} = useMyAccount();

    if (!permissions?.allowEditSavedCards);


    const EditToolbar = () => (
        <Toolbar>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}>
                <SaveButton />
                {permissions?.allowSavedCardDelete && <DeleteButton />}
            </Stack>
        </Toolbar>
    );

    return (
        <EditDialog
            fullWidth
            resource="Card"
            label="switch.portal.myaccount.cards.general.btnedit"
            sx={{ overflow: "hidden" }}
            id={currentCard?.id ? currentCard?.id : record?.id}
            record={{id: currentCard?.id ? currentCard.id : record.id}}
            title={currentCard ? (`${currentCard.brand}  ${t('switch.portal.myaccount.cards.general.detail')}${currentCard.last4}`) : (`${record.brand}  ${t('switch.portal.myaccount.cards.general.detail')}${record.last4}`)}
            open={Open}
            isOpen={dialogState}
            close={Close}
        >
            <Grid sx={{ ml: 1, mt: 0.5 }}>
                <Grid sx={{ ml: 0.5 }} >
                    <Typography>{t('switch.portal.myaccount.cards.general.expiry')} {<DateField source="expiry" />}</Typography>
                </Grid>
            </Grid>
            <SimpleForm toolbar={<EditToolbar />}>
                <BooleanInput source="consentToUse" label={t('switch.portal.myaccount.cards.general.consent')} />
                <BooleanInput source="default" label={t('switch.portal.myaccount.cards.general.default_card')} />
            </SimpleForm>
        </EditDialog>
    );
};