import React, { } from "react";
import {
    useTranslate,
    useRecordContext,
} from 'react-admin';
import { BookingContact } from "../BookingContact";
import { RepeatBookingSummary } from "./RepeatBookingSummary";
import { FutureDates } from "./FutureDates";
import { AccordionForm } from '@react-admin/ra-form-layout';
import { RelatedBookingMobile } from "./RelatedBookingMobile";

export const RepeatBookingShowMobile = () => {
    const record = useRecordContext();
    if (!record) return null
    const t = useTranslate();
    return (
        <AccordionForm autoClose toolbar={false}>
            <AccordionForm.Panel label={t('switch.portal.booking.views.summary.summary')}>
                <RepeatBookingSummary />
            </AccordionForm.Panel>
            <AccordionForm.Panel label={t('switch.portal.booking.views.summary.relatedbookings')}>
                <RelatedBookingMobile />
            </AccordionForm.Panel >
            <AccordionForm.Panel label={t('switch.portal.booking.views.summary.futuredates')}>
                    <FutureDates />
            </AccordionForm.Panel >
            <AccordionForm.Panel label={t('switch.portal.booking.views.contact.contact')}>
                    <BookingContact />
            </AccordionForm.Panel >
        </AccordionForm >
    )
}