import React, { } from 'react';
import { Show } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { BookingShowMobile } from './showViews/BookingShowMobile';
import { BookingShowDesktop } from './showViews/BookingShowDesktop';
import { BookingShowToolBar } from './ToolBar/BookingShowToolBar';
import { BookingPaymentContextProvider } from '../Contexts/useBookingPayment';
import { BookingCancelContextProvider } from '../Contexts/useBookingCancel';



export const ShowBooking = () => {
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    )

    return (
        <Show
            actions={
                <BookingCancelContextProvider>
                    <BookingPaymentContextProvider>
                        <BookingShowToolBar />
                    </BookingPaymentContextProvider>
                </BookingCancelContextProvider>}>

            {isSmall ? <BookingShowMobile /> : <BookingShowDesktop />}
        </Show>
    );
};