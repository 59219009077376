import GetAdjustedDate from '../../GetAdjustedDate';
import { BookingListContextProvider } from '../Contexts/useBookingList';
import { BookingList } from './BookingList';


export const CurrentBookingsList = () => {

    return (
        <BookingListContextProvider>
            <BookingList filter={{ minDate: GetAdjustedDate(new Date()), excludeRepeatBookingHeaders: true }} />
        </BookingListContextProvider>
    );
}

