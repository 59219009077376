import React, { } from "react";
import {
    TabbedShowLayout,
    useRecordContext,
    useTranslate
} from 'react-admin';
import { BookingContact } from "../BookingContact";
import { BookingResources } from "../BookingResources";
import { BookingSummary } from "../BookingSummary";
export const BookingShowDesktop = () => {
    const record = useRecordContext();
    const t = useTranslate()
    if (!record) return null

    return (
        <TabbedShowLayout>
            <TabbedShowLayout.Tab label={t('switch.portal.booking.views.summary.summary')}>
                <BookingSummary />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label={t('switch.portal.booking.views.resource.general.resource')}>
                <BookingResources />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label={t('switch.portal.booking.views.contact.contact')}>
                <BookingContact />
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    )
}