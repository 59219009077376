import React, { useState, useEffect } from "react";
import Api from "../Api";
import { Box, Grommet, Heading, ThemeContext } from "grommet";
import { deepMerge } from "grommet/utils";
import { customTheme, defaultExtendedTheme } from "./theme";

// Component imports
import RoomScreen from "../displayScreen/RoomScreen";
import ReceiptionScreen from "../displayScreen/ReceiptionScreen";
import ReceiptionScreenV2 from "../displayScreen/ReceiptionScreenV2";
import ReceiptionScreenV3 from "../displayScreen/ReceiptionScreenV3";
import { SSEProvider } from "react-hooks-sse";
import useInterval from "../useInterval";
import { FontPicker } from "../ClientPortal/Shared/FontPicker";

function DisplayScreen(props) {
    const [state, setState] = useState({
        errorText: null,
        loading: false,
        bookingPinCheck: null,
    });


    useInterval(() => {
        setState(
            {
                ...state,
                DisplayDate: new Date(),
            })
    }, 10000);

    const widgetKey = window.location.pathname
        .toLowerCase()
        .substring(1)
        .split("screen/")[1];
    const API = new Api({
        widgetKey: widgetKey, //window.location.pathname.substring(1),
        urlRoot: window.location.origin + "/Dispace/Switch/1.0.0/BookingRequest/"
    });

    const venueTheme = !state.theme
        ? customTheme
        : deepMerge(customTheme, {
            global: {
                font: {
                    family: FontPicker(state.theme.paragraph.family),
                    size: state.theme.paragraph.size,
                    weight: state.theme.paragraph.weight
                }
            },
            heading: {
                font: {
                    family: state.theme.paragraph.family
                },
                level: state.theme.levels,
                extend: () => `margin-top: 12px; margin-bottom: 12px;`
            },
            text: {
                large: {
                    size: state.theme.paragraph.size.endsWith('px') ? state.theme.paragraph.size : state.theme.paragraph.size + "px",
                },
            },
            paragraph: {
                size: "28px", //state.theme.fontSizing.p,
                extend: () => `font-weight: 300; margin-top: 0;`,
                xxlarge: {
                    size: "28px"
                }
            }
        });

    const extendedTheme = !state.theme
        ? defaultExtendedTheme
        : {
            global: {
                colors: {
                    ...state.theme.customColors
                }
            }
        };

    const getSettings = () => {
        API.getScreenData().then(result => {
            if (result !== null && result !== undefined) {
                setState({
                    signedIn: true,
                    loading: false,
                    DisplayDate: new Date(),
                    ...result
                });
            }
        }).catch(function (e) {
            setState({
                signedIn: false,
                loading: false,
                errorText: e
            });
        });
    };


    const doSignIn = () => {
        setState({
            ...state,
            errorText: null,
            loading: true
        });

        getSettings();
    };

    const setAgenda = agenda => {
        setState({
            ...state,
            agenda: agenda
        });
    };

    if (state.errorText) {
        return (
            <Grommet theme={customTheme} full>
                <Box justify="center" align="center" background="status-ok" fill>
                    <Heading>Error: {state.errorText}</Heading>
                </Box>
            </Grommet>
        );
    }
    if (!state.loading && !state.signedIn) {
        doSignIn();
    }
    if (state.signedIn) {
        // Change the title of the document
        document.title = !state.title ? "Display Screen | Powered by Switch" : state.title.text + " | Powered by Switch";
        switch (state.screenType) {
            case "reception":
                return (
                    <Grommet theme={venueTheme} full>
                        <ThemeContext.Extend value={extendedTheme}>
                            <SSEProvider
                                endpoint={
                                    window.location.origin +
                                    "/Dispace/Switch/1.0.0/ScreenNotifications?widgetkey=" +
                                    widgetKey
                                }
                            >
                                <ReceiptionScreen state={state} setAgenda={setAgenda} />
                            </SSEProvider>
                        </ThemeContext.Extend>
                    </Grommet>
                );
                break;
            case "abstractRight":
                return (
                    <Grommet theme={venueTheme} full>
                        <ThemeContext.Extend value={extendedTheme}>
                            <SSEProvider
                                endpoint={
                                    window.location.origin +
                                    "/Dispace/Switch/1.0.0/ScreenNotifications?widgetkey=" +
                                    widgetKey
                                }
                            >
                                <ReceiptionScreenV2 state={state} setAgenda={setAgenda} />
                            </SSEProvider>
                        </ThemeContext.Extend>
                    </Grommet>
                );
                break;
            case "abstractLeft":
                return (
                    <Grommet theme={venueTheme} full>
                        <ThemeContext.Extend value={extendedTheme}>
                            <SSEProvider
                                endpoint={
                                    window.location.origin +
                                    "/Dispace/Switch/1.0.0/ScreenNotifications?widgetkey=" +
                                    widgetKey
                                }
                            >
                                <ReceiptionScreenV3 state={state} setAgenda={setAgenda} />
                            </SSEProvider>
                        </ThemeContext.Extend>
                    </Grommet>
                );
                break;
            case "room":
                return (
                    <Grommet theme={venueTheme} full>
                        <ThemeContext.Extend value={extendedTheme}>
                            <SSEProvider
                                endpoint={
                                    window.location.origin +
                                    "/Dispace/Switch/1.0.0/ScreenNotifications?widgetkey=" +
                                    widgetKey
                                }
                            >
                                <RoomScreen state={state} api={API} getSettings={getSettings} />
                            </SSEProvider>
                        </ThemeContext.Extend>
                    </Grommet>
                );
                break;
            default:
                return (
                    <Grommet theme={venueTheme} full>
                        <ThemeContext.Extend value={extendedTheme}>
                            <SSEProvider
                                endpoint={
                                    window.location.origin +
                                    "/Dispace/Switch/1.0.0/ScreenNotifications?widgetkey=" +
                                    widgetKey
                                }
                            >
                                <ReceiptionScreen state={state} />
                            </SSEProvider>
                        </ThemeContext.Extend>
                    </Grommet>
                );
                break;
        }
    } else {
        // Change the title of the document
        document.title = "Loading... | Powered by Switch";
        return (
            <Grommet theme={venueTheme} full>
                <Box justify="center" align="center" background="black" fill>
                    <Heading color="white">Loading Data</Heading>
                </Box>
            </Grommet>
        );
    }
}

export default DisplayScreen;
