import React, {useContext} from 'react'
import {
  Add,
  Subtract
} from 'grommet-icons';
import {
  TextInput,
  Button,
  Grid,
  Box,
  ResponsiveContext
} from 'grommet';
import { ScreenSizeToNum } from './functions';

const PlusMinusComponent = (props) => {
    const { type, val } = props;
    const { addButton, subButton } = props.buttonsFn
    const ScreenSizeNum = ScreenSizeToNum(useContext(ResponsiveContext))

  return(
    <Box>
    <Grid
      rows={['1/3', '1/3', '1/3']}
      columns={["100%", "0%"]}
      justifyContent="around"
      justify="center"
      gap="small"
      areas={[
        { name: 'plus', start: [0, 0], end: [0, 0] },
        { name: 'element', start: [0, 1], end: [0, 1] },
        { name: 'minus', start: [0, 2], end: [0, 2] }
      ]}
      fill
    >
      <Box gridArea="plus" align="center">
        <Button 
          primary 
          size={(ScreenSizeNum !== 0) ? "xlarge" : "medium" }
          a11yTitle="Add"
          icon={<Add size={(ScreenSizeNum !== 0) ? "large" : "medium" } />}
          onClick={() => {addButton(type)}} 
        />
      </Box>
      <Box gridArea="element" align="center">
        <TextInput
          size={(ScreenSizeNum !== 0) ? "xlarge" : "medium" }
          textAlign="center"
          value={val}
          style={{backgroundColor: '#fff'}}
        />
      </Box>
      <Box gridArea="minus" align="center">
        <Button 
          primary 
          size={(ScreenSizeNum !== 0) ? "xlarge" : "medium" }
          a11yTitle="Add"
          icon={<Subtract size={(ScreenSizeNum !== 0) ? "large" : "medium" }/>}
          onClick={() => {subButton(type)}} 
        />
      </Box>
    </Grid>
    </Box>
  );
};

export default PlusMinusComponent