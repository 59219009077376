import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { Box, Typography, Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Checkbox, FormControl, RadioGroup, Radio, CircularProgress } from "@mui/material";
import CheckoutCardForm from "../../../guestPortalComponents/CheckOutCardForm";
import { usePayment } from "../../../contexts/PaymentContext";
import { useTheme } from '@mui/material/styles'
import CVCCaptureForm from "../../../CVCCaptureForm";
import { useTranslate } from 'react-admin';
import { useBookingPayment } from "../../Contexts/useBookingPayment";


export const RenderStripePaymentForm = () => {
    const translate = useTranslate()
    const theme = useTheme() 
    const {
        stripePromise,
        consent,
        ChangeConsent,
        saveDefault,
        ChangeDefault,
        hasSavedCards,
        stripeOptions,
        savedCards,
        error,
        handleChangeCard,
        SetCardType,
        stripeStyleOptions
    } = usePayment();

    const bookingPaymentContext = useBookingPayment()
    let stripeDetails = {}

    if (!stripeOptions) {
        stripeDetails = {
            ...bookingPaymentContext?.stripeDetails
        }
    } else {
        stripeDetails = {
            ...stripeOptions
        }
    }

    const [expanded, setExpanded] = useState(savedCards.length > 0 ? 1 : 2);

    const handleChange = (panel, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    
    return (
        <Box sx={{ p: 1 }}>
            {hasSavedCards &&
                <Accordion expanded={expanded === 1} onChange={(event, expanded) => {
                    expanded ? SetCardType('saved') : SetCardType('newcard')
                    handleChange(1, expanded)
                }}>
                    <AccordionSummary>
                        <Typography>{translate('switch.portal.paymentslist.savedcard')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <FormControl>
                                <RadioGroup
                                    onChange={handleChangeCard}
                                >
                                    {savedCards.map((card) => {
                                        const cardExpiryDate = new Date(card.expiry)
                                        const expiryMonth = new Intl.DateTimeFormat('en-GB', { month: '2-digit' }).format(cardExpiryDate);
                                        const expiryDate = `${expiryMonth}/${cardExpiryDate.getFullYear()}`
                                        return <FormControlLabel label={`*** ${card.last4} - Expires ${expiryDate}`} control={<Radio value={card.id} />} />

                                    })}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box>
                            <Elements stripe={stripePromise} options={{ ...stripeStyleOptions, ...stripeDetails }} >
                                <CVCCaptureForm label={translate("switch.portal.paymentslist.cvcconfirm")}/>
                            </Elements>
                        </Box>
                    </AccordionDetails>
                </Accordion>}
            <Accordion onChange={(event, expanded) => {
                handleChange(2, expanded)
                expanded ? SetCardType('newcard') : SetCardType('saved')
            }
            } expanded={expanded === 2}>
                <AccordionSummary>
                    <Typography>{translate('switch.portal.paymentslist.newcard')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Elements stripe={stripePromise} options={{ ...stripeStyleOptions, ...stripeDetails }}>
                        <CheckoutCardForm />
                    </Elements>
                </AccordionDetails>
            </Accordion>

            <Box sx={{ pt: 1 }}>
                <FormControlLabel
                    control={<Checkbox
                        checked={consent}
                        onChange={ChangeConsent}
                    />}
                    label={translate('switch.portal.paymentslist.futurepayments')}
                />
            </Box>
            <Box>
                <FormControlLabel
                    control={<Checkbox
                        checked={saveDefault}
                        onChange={ChangeDefault}
                    />}
                    label={translate('switch.portal.paymentslist.defaultcard')}
                />
            </Box>
            <Box>
                <Box>
                    {error && <Typography>{error}</Typography>}
                </Box>
            </Box>
        </Box>
    )
}