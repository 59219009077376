import { Datagrid, DateField, List, NumberField, TextInput, TextField, DateInput, FunctionField } from 'react-admin';

const PaymentsFilter = [
    <TextInput label="Description" source="description" resettable />,
    <DateInput label="Due Date" source="maxduedate" resettable defaultValue={new Date()} />,
]

export const PaymentList = () => (
    <List
        exporter={false}
        queryOptions={{ staleTime: 5 * 60 * 1000 }}
        bulkActionButtons={false}
        sort={{ field: 'dueDate', order: 'ASC' }}
        filters={PaymentsFilter}

    >
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="description" label='switch.portal.paymentslist.description' />
            <FunctionField label='switch.portal.paymentslist.duedate' render={record => record?.dueDate < new Date().toISOString() ? 
                <DateField source="dueDate" locales="en-GB" sx={{ color: 'red' }} /> : 
                <DateField source="dueDate" locales="en-GB"/>} />
            <NumberField label='switch.portal.paymentslist.amount' source="amount" options={{
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2
            }} />
        </Datagrid>
    </List>
);