import PaymentCheckoutForm from "./Stripe/PaymentCheckoutForm";
import { WindCaveCardsCheck } from "./WindCave/WindCaveCardsCheck";
import { usePayment } from "../../contexts/PaymentContext";
import HostedPaymentPortal from "./WindCave/HostedPaymentPortal";
import PaymentRedirect from "./WPM/PaymentRedirect";
import { useTranslate, Loading } from "react-admin"

export default function PaymentProcessorSelector() {
    const {
         processingType,
        savedCardStepWindCave,
        monitoring
    } = usePayment();
    const translate = useTranslate()

    if (monitoring) return (<Loading loadingPrimary={translate('switch.portal.paymentslist.waitingforupdate')}/>)

    const WindCaveSteps = () => {
        if (savedCardStepWindCave) {
            return (<WindCaveCardsCheck />)
        } else {
            return (<HostedPaymentPortal/>)
        }
    };
    switch (processingType) {

        case "stripe":
            return (
                <PaymentCheckoutForm />
            );
        case "pay360SCP":
            return (<HostedPaymentPortal />)
        case "WPM":
            return (<PaymentRedirect />)
        case "windCave":
            return (
                <WindCaveSteps />
            );
        default:
            return null;
    }
}
