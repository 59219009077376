import React, { } from "react";
import {
    useRecordContext,
    ArrayField,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    useRedirect,
} from 'react-admin';
import FormattedTime from "../../Shared/FormattedTime";



export const RelatedBookings = () => {
    const record = useRecordContext();
    if (!record) return null
    const redirect = useRedirect()

    const rowClick = (id, resource, record) => {
        redirect('show', 'booking', id);
    };

    return (

        <ArrayField source="relatedBookings">
            <Datagrid rowClick={rowClick} bulkActionButtons={false}>
                <TextField source="switchURN" label='switch.portal.bookingshow.urn' />
                <TextField source="bookingStatus" label='switch.portal.bookingshow.status' />
                <DateField source="bookingDate" locales="en-GB" label='switch.portal.bookingshow.startdate' />
                <FormattedTime source="startTime" label='switch.portal.bookingshow.starttime' />
                <FormattedTime source="endTime" label='switch.portal.bookingshow.endtime' />
                <NumberField source="numberOfGuests" label='switch.portal.bookingshow.guests' />
                <NumberField source="outstandingBalance" label='switch.portal.bookingshow.lists.desktop.balance' options={{
                    style: 'currency',
                    currency: 'GBP',
                    minimumFractionDigits: 2
                }} />
            </Datagrid>
        </ArrayField>

    )
}