import { Show, SimpleShowLayout, DateField, NumberField, ListButton, TopToolbar, TextField, ReferenceField, useTranslate, useRedirect } from 'react-admin';
import { PayButton } from './PayButton';
import { PayBookingContextProvider, usePayBooking } from '../Contexts/usePayBooking';
import { PaymentDialog } from './PaymentDialog';
import { Button } from '@mui/material';



const PaymentShowInfo = () => {

    return (
        <SimpleShowLayout >
            <TextField source="description" label='switch.portal.paymentslist.description' />
            <TextField source="bookingRef" label='switch.portal.paymentslist.reference' />
            <DateField label='switch.portal.paymentslist.duedate' source="dueDate" locales="en-GB" />
            <NumberField label='switch.portal.paymentslist.amount' source="amount" options={{
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2
            }} />
            <ReferenceField source='venueId' reference="venue" label='switch.portal.paymentslist.venue'>
                <TextField source='name' />
            </ReferenceField>
            <DateField source="bookingDate" locales="en-GB" label='switch.portal.paymentslist.bookingdate' />
            <TextField source="bookingStatus" label='switch.portal.paymentslist.bookingstatus' />
        </SimpleShowLayout>
    )
}

const ShowActionbar = () => {
    const translate = useTranslate()
    const redirect = useRedirect()

    return (
        <TopToolbar sx={{ marginLeft: "auto", p: 2, borderRadius: 1 }}>
            <Button sx={{ mb: 1 }} onClick={ () => redirect('list', 'payments') }>{translate('switch.portal.enquiry.backbutton')}</Button>
                <PayButton />
            <PaymentDialog /> 
        </TopToolbar>
    )
}

export const PaymentShow = () => {

    return (
        <PayBookingContextProvider>
            <Show actions={<ShowActionbar />}>
                <PaymentShowInfo />
            </Show>
        </PayBookingContextProvider>


    )
}