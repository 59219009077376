import * as React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import { datetime, RRule } from 'rrule'
import { useRecordContext, useList, useUpdate, useResourceContext } from 'react-admin'
import GetAdjustedDate from '../../GetAdjustedDate'

export const useRepeatBooking = () => {
    const context = useRepeatBookingContext()

    return context
}

export const RepeatBookingContext = createContext(undefined)


export const RepeatBookingContextProvider = ({ context, children }) => {
    // State Variables

    const [ruleString, setRuleString] = useState()
    const [ruleText, setRuleText] = useState()
    const [setpos, setSetpos] = useState([])
    const [settings, setRRule] = React.useState()
    const [initialised, setInitialised] = useState(false)
    const [heldDates, setHeldDates] = useState([])
    const [excludedDates, setExcludedDates] = useState([]);
    const [update, { isLoading, error }] = useUpdate();
    const [day, setDay] = useState();

    const record = useRecordContext()
    const resource = useResourceContext()


    const heldDatesListContext = useList({
        data: heldDates,
        ids: heldDates.map(b => {
            return b.id
        }),
        sort: { field: 'bookingDate', order: 'ASC' },
        isLoading: false,
    })
    function CalculateHeldDates() {


        let newHeldDates = []

        if (record.repeatType != 'adHoc') {
            if (!record?.repeatRule) return;
            let rruleSettings = new RRule.fromString(record.repeatRule)
            const start = new Date(record.bookingDate);
            const end = new Date(record.bookingEndDate);
            rruleSettings.options.dtstart = datetime(start.getFullYear(), start.getMonth() + 1, start.getDate())
            rruleSettings.options.until = datetime(end.getFullYear(), end.getMonth() + 1, end.getDate(), 23, 59)
            let i = 1
            let dates = rruleSettings.all()

            dates.forEach(date => {
                date.setHours(0, 0, 0, 0)
                if (
                    resource === 'Enquiry' ||
                    record.relatedBookings?.filter(
                        rb => new Date(rb.bookingDate).toISOString() == date.toISOString()
                    ).length === 0
                ) {
                    const heldDate = {
                        ...record,
                        bookingDate: GetAdjustedDate(date),
                        id: i,
                    }
                    newHeldDates.push(heldDate)
                    i += 1
                }
            })
        } else {
            let i = 1
            record.adHocDates.forEach(adHocDate => {
                let date = new Date(adHocDate.bookingDate)
                date.setHours(0, 0, 0, 0)
                if (date > new Date()) {
                    if (
                        record?.relatedBookings?.filter(
                            rb => new Date(rb.bookingDate).toISOString() == date.toISOString()
                        ).length === 0
                    ) {
                        const heldDate = {
                            ...record,
                            id: i,
                            bookingDate: GetAdjustedDate(date),
                        }
                        newHeldDates.push(heldDate)
                        i += 1
                    }
                }
            })
        }

        setHeldDates(newHeldDates);
    }


    useEffect(() => {
        if (!record) return;
        _InitialiseRule(record?.repeatRule)
        CalculateHeldDates();
    }, [record]);


    useEffect(() => {
        if (!settings || !ruleString) return;
        setRuleText(settings.toText());
        const bDayPos = ruleString.indexOf(";BYDAY");
        if (bDayPos == -1) return;

        if (setpos > 1 && day) {
            // There is a known bug in RRULE that NLP does not work when SetPos value is > 1
            // Work Around is to add the number before the ByDay setting i.e. 2MO = 2nd Monday
            // Unsure of impact on other uses so only using this to generate the NLP text

            const i = ruleString.indexOf(";BYDAY") + 7
            const text = ruleString.substr(0, i) + setpos + ruleString.substr(i)
            const r = new RRule.fromString(text);
            setRuleText(r.toText())
        }
    }, [ruleString, settings]);

    useEffect(() => {
        if (resource === 'Enquiry') return;

        let exclusions = []

        record?.scheduleExclusions.map((exclusion) => {
            exclusions.push(exclusion.bookingDate)
        })

        setExcludedDates(exclusions)
    }, [record?.scheduleExclusions])


    const _InitialiseRule = (rule) => {
        if (initialised) return;
        if (rule?.length > 0) {
            const r = RRule.fromString(rule);

            setRRule(r)
            setDay(r?.options?.byweekday)
            setSetpos(r?.options?.bysetpos ? r?.options?.bysetpos : [])
            setRuleString(rule)
            setInitialised(true)
            return
        }

    }

    const IsDateExcluded = (bookingDate) => {
        return excludedDates.includes(bookingDate.slice(0, -5)) ? true : false
    }

    const ExcludeRepeatBooking = (bookingDate) => {

        update('repeatbooking', { id: record?.id, data: { api: "exclude", bookingDate: bookingDate } },
            {
                onSuccess: ({ data }) => {
                    notify('Future Date Canceled', { type: 'info' });
                },
                onError: (error) => {
                    notify('Error:' + error.message, { type: 'warning' });
                },
            }
        );
    }


    let contextData = {
        heldDates: heldDatesListContext,
        isLoading,
        ruleString,
        ruleText,
        ExcludeRepeatBooking,
        IsDateExcluded,
        ...context
    }

    //console.log(contextData)
    return (
        < RepeatBookingContext.Provider value={contextData} >
            {children}
        </RepeatBookingContext.Provider >
    )
}

export const useRepeatBookingContext = () => useContext(RepeatBookingContext)