import React, { } from "react";
import { SolarMenu } from "@react-admin/ra-navigation";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useParams } from "react-router-dom";
import {
    useTranslate,
    usePermissions
} from "react-admin";

export const Menu = () => {
    const { client } = useParams();
    const t = useTranslate();
    const { isLoading, permissions } = usePermissions();
    
    return (
        <SolarMenu>
            <SolarMenu.Item
                label={t('switch.portal.menu.dashboard')}
                icon={<DashboardIcon />}
                name="Dashboard"
                to={"/portal/" + client }
            />
            {permissions?.showProfile  && <SolarMenu.Item
                label={t('switch.portal.menu.myaccount')}
                icon={<AccountCircleIcon />}
                name="Account"
                to={"/portal/" + client + "/Account"}
            />}
            {permissions?.showQuotes && <SolarMenu.ResourceItem name="Quote" />}
            {permissions?.showBookings && <SolarMenu.ResourceItem name="Booking" />}
            {permissions?.showEnquiries && <SolarMenu.ResourceItem name="Enquiry" />}
            {permissions?.showPayments && <SolarMenu.ResourceItem name="Payments" />}
            {permissions?.showHistory && <SolarMenu.ResourceItem name="History" />}
            {permissions?.showInvoices && <SolarMenu.ResourceItem name="Invoice" />}
        </SolarMenu>
    )
}