import React, { } from "react";
import {
    TabbedShowLayout,
    useRecordContext,
    useTranslate
} from 'react-admin';
import { BookingContact } from "../BookingContact";
import { RepeatBookingSummary } from "./RepeatBookingSummary";
import { RelatedBookings } from "./RelatedBookings";
import { FutureDates } from "./FutureDates";



export const RepeatBookingShowDesktop = () => {
    const record = useRecordContext();
    if (!record) return null
    const t = useTranslate()

    return (
        <TabbedShowLayout>
            <TabbedShowLayout.Tab label={t('switch.portal.booking.views.summary.summary')}>
                <RepeatBookingSummary />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label={t('switch.portal.booking.views.summary.relatedbookings')}>
                <RelatedBookings />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label={t('switch.portal.booking.views.summary.futuredates')} >
                <FutureDates />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label={t('switch.portal.booking.views.contact.contact')}>
                <BookingContact />
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    )
}