import React from 'react'
import { useTranslate, usePermissions, useRecordContext } from 'react-admin'
import { Button } from '@mui/material';
import { usePayBooking } from '../Contexts/usePayBooking';


export const PayButton = () => {
    const { OpenBookingPayDialog } = usePayBooking()
    const translate = useTranslate()
    const { permissions } = usePermissions();
    const record = useRecordContext();

    if (!permissions?.allowPayment) return;
    if (!record || record?.paymentStatus !== "awaitingPayment") return;

    return (
        <Button sx={{mb: 1}} onClick={() => OpenBookingPayDialog()}> {translate('switch.portal.bookingshow.pay')} </Button >
    )
}
