import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { usePayment } from "../../../contexts/PaymentContext";
import { useTranslate } from 'react-admin';


export const PaymentSuccess = (props) => {
    const translate = useTranslate()
    const {
        onSuccess
    } = props;

    const { paymentRecord } = usePayment();

    if (onSuccess != null) {
        onSuccess({
            ...paymentRecord,
            paymentStatus: "paid"
        });
    }

    return (<Typography>{translate('switch.portal.paymentslist.paymentsuccess')}</Typography>)
}
