import React from 'react'
import { 
  Return,
  Erase
} from 'grommet-icons';
import {
  Box,
  Grid,
  Text
} from "grommet";

function keySwitch(key) {
  switch (key) {
    case "B":
      return <Erase size="medium" />

    case "E":
      return <Return size="medium" />

    default:
      return key
  }
}

const Keypad = (props) => {
  const { keyPress } = props;
  const keys = [1,2,3,4,5,6,7,8,9,'B',0,'E']
  return(
    <Grid
      rows={['25%', '25%', '25%', '25%']}
      columns={['30%', '30%', '30%']}
      gap="small"
      pad={{top: '0%', right: '25%', bottom: '0%', left: '25%'}}
      justifyContent="around"
      areas={[
        // Row 1
        { name: 'key1', start: [0, 0], end: [0, 0] },
        { name: 'key2', start: [1, 0], end: [1, 0] },
        { name: 'key3', start: [2, 0], end: [2, 0] },
        // Row 2
        { name: 'key4', start: [0, 1], end: [0, 1] },
        { name: 'key5', start: [1, 1], end: [1, 1] },
        { name: 'key6', start: [2, 1], end: [2, 1] },
        // Row 3
        { name: 'key7', start: [0, 2], end: [0, 2] },
        { name: 'key8', start: [1, 2], end: [1, 2] },
        { name: 'key9', start: [2, 2], end: [2, 2] },
        // Row 4
        { name: 'keyB', start: [0, 3], end: [0, 3] },
        { name: 'key0', start: [1, 3], end: [1, 3] },
        { name: 'keyE', start: [2, 3], end: [2, 3] },
      ]}
      fill="horizontal"
    >
      {
        keys.map((key, idx) => {
          return(
            <Box onClick={() => {keyPress(key)}} key={idx} gridArea={"key"+key} align="center" alignSelf="center" background="brand" elevation="medium" style={{borderRadius: '24px'}}>
              <Text size="xlarge" weight={600} margin={{vertical: "small", horizontal: "xsmall"}}>
                {keySwitch(key)}
              </Text>
            </Box>
          )
        })
      }
    </Grid>
  )
}

export default Keypad;