import { useState, useEffect } from "react"

const useDebounce = (value, delay) => {
    const [debouncedValue, setDebounceValue] = useState(value);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebounceValue(value);
        }, delay);
        return () => clearTimeout(timeoutId);
    }, [value]);

    return debouncedValue;
}

export default useDebounce;