import * as React from 'react';
import { useTranslate, usePermissions } from 'react-admin';
import CardWithIcon from './CardWithIcon';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

const CurrentBookings = (props) => {
    const { value } = props;
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (!permissions?.showBookings) return;
    return (
        <CardWithIcon
            to="Booking"
            icon={EventAvailableIcon}
            title={translate('switch.portal.dashboard.currentbookings')}
            subtitle={value}
        />
    );
};

export default CurrentBookings;