import GetAdjustedDate from '../../GetAdjustedDate';
import { BookingListContextProvider } from '../Contexts/useBookingList';
import { BookingList } from './BookingList';



export const BookingHistoryList = () => {

    return (
        <BookingListContextProvider>
            <BookingList filter={{ maxDate: GetAdjustedDate(new Date()) }} />
        </BookingListContextProvider>

    );
}
