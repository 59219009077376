import React from 'react';
import { FunctionField } from 'react-admin';

const formatTime = (value) => {
    const date = new Date(`1970-01-01T${value}Z`); // Combine with a dummy date
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'UTC' });
};

const FormattedTime = ({ record, source }) => (
    <FunctionField
        record={record}
        source={source}
        render={(record) => formatTime(record[source])}
    />
);

export default FormattedTime;
