import { usePayment } from "../../../contexts/PaymentContext";
import { Typography } from '@mui/material';
import { useTranslate } from 'react-admin';



export const OnPayMessage = () => {
    const { iFrameResult, error } = usePayment()
    const translate = useTranslate()
    if (!iFrameResult) return;

    let completionCondition = iFrameResult?.result;

    const message = completionCondition === "success" ?  translate('switch.portal.paymentslist.paymentsuccess') : translate("switch.portal.paymentslist.paymentfail") + `${error}`

    return (
            <Typography>{message}</Typography>
    )
}