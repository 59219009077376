import React from "react";
import ErrorBoundary from './../ErrorBoundary'
import { customTheme } from "./../pages/theme";
import { Grommet, Card, CardHeader, CardBody, CardFooter, Button } from "grommet";
import { Bluetooth as Memory, Storage, Trigger } from 'grommet-icons';
import { deepMerge } from "grommet/utils";
import { FontPicker } from "../ClientPortal/Shared/FontPicker";
export default function WidgetTableBooking({ context }) {

    const { widgetTheme } = context;

    const wTheme = !widgetTheme ? customTheme : deepMerge(customTheme, {
      global: {
            font: {
              family: context.widgetTheme.headerFont === "Big Caslon" ? "big-caslon-fb,“Big Caslon”,“Book Antiqua”,Palatino,Georgia,serif" : FontPicker(context.widgetTheme.font),
          },
          fontStyles: {
            fontStyle: context.widgetTheme.fontStyle,
            primaryStyle: context.widgetTheme.primaryStyle,
            secondaryStyle: context.widgetTheme.secondaryStyle,
          },
          colors: {
              brand: {
                  primary: context.widgetTheme.primaryColour,
                  secondary: context.widgetTheme.secondaryColour,
              },
          }
      }
  }
  );

  return (
      <ErrorBoundary>
          <Grommet theme={wTheme} full>

                <Card  height="small" width="small" background="light-1">
                      <CardHeader pad="medium">Header</CardHeader>
                      <CardBody pad="medium">Body</CardBody>
                      <CardFooter pad={{horizontal: "small"}} background="light-2">   
                      <Button
                      icon={<Storage color="red" />}
                      hoverIndicator
                    />
                    <Button icon={<Memory size="large" />} hoverIndicator />
                    </CardFooter>
                </Card>

          </Grommet>
      </ErrorBoundary>
  );

}