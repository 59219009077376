import * as React from 'react'
import {
    createContext,
    useContext,
    useState,
    useEffect
} from 'react'
import {
    useUpdate,
    useNotify,
    useRecordContext,
    useDataProvider,
    useRedirect
} from "react-admin";

export const useBookingCancel = () => {
    const context = useBookingCancelContext()

    return context
}

export const BookingCancelContext = createContext(undefined)

export const BookingCancelContextProvider = ({ context, children}) =>
{

    const currentRecord = useRecordContext();
    const notify = useNotify()
    const [allowCancel, setAllowCancel] = useState(false)
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false)
    const [cancellationReason, setCancellationReason] = useState(null)
    const [refundAmount, setRefundAmount] = useState()
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false)
    const redirect = useRedirect();

    const OpenCancelDialog = () => {
        setCancelDialogIsOpen(true);
    };
    const CloseCancelDialog = () => {
        setCancelDialogIsOpen(false);
        setCancellationReason(null)
    };

    useEffect(() => {
        if (!currentRecord) return;
        const allow = new Date(currentRecord?.bookingDate) > new Date();
        setAllowCancel(allow);
    }, [currentRecord])

    const [update, { isLoading, error }] = useUpdate(
        'booking',
        { id: currentRecord?.id, data: { api: 'cancel', cancellationReason: cancellationReason }, previousData: currentRecord },
        {
            onSuccess: () => {
                setLoading(false)
                setCancelDialogIsOpen(false)
                notify('Booking Cancelled')
                redirect('list', 'Booking');
            },

            onError: (err) => {
                setLoading(false)
                setCancelDialogIsOpen(false)
                notify(`Error: ${err}`, { type: 'warning' })

            }
        }
    );
    useEffect(() => {
        if(!currentRecord) return
        dataProvider.CalculateRefund(currentRecord?.id, currentRecord).then(response => {
            setRefundAmount(parseFloat(response?.data).toFixed(2) )
        }).catch(e => {
            notify(`Error: ${e}`, { type: 'warning' })
        })
    }, [cancelDialogIsOpen])
    

    const cancelBooking = () => {
        setLoading(true)
        update()
    }
    
    const SetCancellationReason = (reason) => {
        setCancellationReason(reason)
    }
    

    let contextData = {
        allowCancel,
        cancelDialogIsOpen: cancelDialogIsOpen,
        setCancelDialogIsOpen: setCancelDialogIsOpen,
        SetCancellationReason: SetCancellationReason,
        cancellationReason: cancellationReason,
        OpenCancelDialog: OpenCancelDialog,
        cancelBooking: cancelBooking,
        isLoading: isLoading,
        CloseCancelDialog: CloseCancelDialog,
        refundAmount,
        loading,
        ...context
    }

    //console.log(contextData)

    return (
        < BookingCancelContext.Provider value = { contextData } >
        { children }
        </BookingCancelContext.Provider >
    )
}

export const useBookingCancelContext = () => useContext(BookingCancelContext)