import { usePayment } from "../contexts/PaymentContext";
import { Loading } from "react-admin";

export default function HostedPaymentPortal() {
    const {
        redirectURL,
        showIFrame,
        processing,
        error,
        setiFrameResult,
        iFrameResult
    } = usePayment();

    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    // Listen to message from child window
    eventer(messageEvent, function (e) {
        var key = e.message ? "message" : "data";
        var data = e[key];
        if (data?.hasOwnProperty("source") && data?.source === "payresult") {
            setiFrameResult(data)
        }
    }, false);

    let completionCondition = iFrameResult?.result;
    if (processing) {
        return (
            <Loading loadingPrimary={"Preparing Payment Data..."} loadingSecondary="Please wait" />
        )
    }

    if (showIFrame) {
        return (
            <iframe
                className="responsive-iframe"
                src={redirectURL}
                width="100%"
                height="500px"
            ></iframe>
        );
    }
    const OnPayMessage = () => {
        if (!iFrameResult) return;
        let message;
        if (completionCondition === "success") {
            message = "Your payment was successful!"
        } else {
            message = `There was a problem with your transaction - code: ${error}`
        }
        return (<div className="sr-field-success message">
            <h1>{message}</h1>
        </div>
        )
    }

    return (
        //<>
        //{completionCondition !== "successs" ? <p>There was a error proccesing your payment with the message: {error}</p>
        //    : <p>Your payment was succesful!</p>}</>
        <OnPayMessage />

    )

}
