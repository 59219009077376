import { usePayment } from "../../../contexts/PaymentContext";
import { Box } from "grommet";
import { Typography } from '@mui/material';
import { RenderHostedPaymentForm } from "./RenderHostedPaymentForm";
import { OnPayMessage } from "./OnPayMessage";
import { useTranslate } from 'react-admin';


export default function HostedPaymentPortal() {
    const translate = useTranslate()
    const {
        processing,
        setiFrameResult,
        iFrameResult
    } = usePayment();

    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    // Listen to message from child window
    eventer(messageEvent, function (e) {
        var key = e.message ? "message" : "data";
        var data = e[key];
        if (data?.hasOwnProperty("source") && data?.source === "payresult") {
            setiFrameResult(data)
        }
    }, false);

    if (processing) {
        return (
            <>
                <Typography align='center' sx={{ p: 2, }}>{translate('switch.portal.paymentslist.preparingpaymentdata')}</Typography>
            </>
        )
    }

    return (
        <>
            <RenderHostedPaymentForm />
            {iFrameResult ? <OnPayMessage /> : null }
        </>
    )

}
