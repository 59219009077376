import React, { } from "react";
import {
    SimpleList,
    ReferenceField,
    TextField,
    useTranslate
} from 'react-admin';
import FormatDate from "../../../components/FormatDate";
import { SimpleListRowStyle } from "../../Shared/SimpleListRowStyle";

export const QuoteListMobile = () => {
    const t = useTranslate();
    return (
        <SimpleList
            rowSx={SimpleListRowStyle}
            primaryText={<ReferenceField
                source="enquiryId"
                reference="enquiry"
                link={false}
                queryOptions={{ staleTime: 5 * 60 * 1000 }}
            >
                <TextField source="publicEventDescription" />
            </ReferenceField>}
            secondaryText={record => `${t('switch.portal.quote.list.mobile.expiry')}${FormatDate(record.expiryDate)} | ${record.status}`}
            tertiaryText={record => `${t('switch.portal.quote.list.mobile.gross')} ${record.gross.toFixed(2)}`}
            linkType={'show'}
        />
    )
}