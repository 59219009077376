import React from "react";
import { useTranslate, Loading } from 'react-admin';
import { CurrencyFormat } from "../../guestPortalComponents/CurrencyFormat";
import {
    Dialog, Box, Typography, Button, FormControlLabel, RadioGroup, FormControl, Radio, DialogContent, DialogActions, Stack
} from '@mui/material';

import { useBookingPayment } from "../Contexts/useBookingPayment";
import { NextButton } from "./BookingPaymentNextButton";

export const BookingPayment = () => {
    const translate = useTranslate()
    const { isLoading, isOpen, availableOptions, SelectPaymentOption, selectedOption, selectedAmount, CloseDialog, outstandingBalance } = useBookingPayment();

    const RenderPaymentForm = () => {
        if (isLoading) {
            return <Loading />
        }


        return (
            < React.Fragment >
                <DialogContent>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        {availableOptions.length > 1 &&
                            <FormControl>
                                <RadioGroup
                                    row
                                    onChange={(event) => SelectPaymentOption(event.target.value)}
                                    value={selectedOption}
                                >
                                    <Stack
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={2}
                                    >
                                        <FormControlLabel control={<Radio />} value={`${availableOptions[0].value}`} label={`${availableOptions[0].label}`} />
                                        <FormControlLabel control={<Radio />} value={`${availableOptions[1].value}`} label={`${availableOptions[1].label}`} />
                                        {availableOptions.length == 3 ?
                                            <FormControlLabel control={<Radio />} value={`${availableOptions[2].value}`} label={`${availableOptions[2].label}`} />
                                            : null
                                        }
                                    </Stack>

                                </RadioGroup>
                            </FormControl>
                        }
                        <Box direction='row' align='right' sx={{ mr: 1 }}>
                            <Typography>{translate('switch.portal.paymentslist.amountdue')} {CurrencyFormat(selectedAmount ? selectedAmount : outstandingBalance)}</Typography>
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                    >
                        <Button id="payCancel" onClick={CloseDialog}> {translate('switch.portal.paymentslist.cancel')} </Button>

                        <NextButton />
                    </Stack>
                </DialogActions>
            </React.Fragment>
        )
    }





    return (
        <Dialog open={isOpen}>
            <Box direction="column" pad="small" margin="small" overflow="auto">
                <Box sx={{ p: 1 }}>
                    <RenderPaymentForm />
                </Box>
            </Box>
        </Dialog>
    )
}

