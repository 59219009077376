import React from "react";
import "./App.css";
import CacheBuster from './CacheBuster';
import Widget from "./pages/Widget";
import DisplayScreen from "./pages/DisplayScreen";
import BookingManager from "./pages/bookingManagement";
import PaymentPage from "./pages/PaymentPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PayResult } from "./components/PayResult";
import { Portal } from "./ClientPortal/Portal";

function App() {

    return (
        // run npm version patch / minor / major - to update version number and force cache update
        <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    refreshCacheAndReload();
                }

                return (
                    <Routes>
                        <Route exact path="/Booking/" element={<BookingManager />} />
                        <Route path="/payresult/" element={<PayResult />} />
                        <Route exact path="/Payment/:bookingRef/:paymentRef" element={<PaymentPage/>} />
                        <Route exact path="/Booking/:bookingRef" element={<BookingManager/>} />
                        <Route exact path="/Booking/:email/:bookingref" element={<BookingManager/>} />
                        <Route exact path="/Guest/" element={<BookingManager/>} />
                        <Route exact path="/Guest/:bookingRef" element={<BookingManager/>} />
                        <Route exact path="/Guest/:email/:bookingref" element={<BookingManager/>} />
                        <Route exact path="/" element={<Widget/>} />
                        <Route exact path="/Screen/:widgetKey/" element={<DisplayScreen />} />
                        <Route path="/Portal/:client/*" element={<Portal />} />
                        <Route path="/:widgetKey//*" element={<Widget />} />
                        

                        
                        {/*<Route component={Error} />*/}
                    </Routes>
                );
            }}
        </CacheBuster>
    );
}

export default App;
