import React, { } from "react";
import {
    ReferenceField,
    TextField,
    useTranslate,
    useRecordContext
} from 'react-admin';
import {
    Typography,
    Grid, Card
} from '@mui/material';
import FormatDate from "../../../components/FormatDate";
import { useRepeatBooking } from "../../Contexts/useRepeatBooking";


export const RepeatBookingSummary = () => {
    const record = useRecordContext();
    const t = useTranslate()
    const { ruleText } = useRepeatBooking();
    const showGuestNumber = (number) => {
        return number > 1 ? `${number} ${t('switch.portal.enquiry.guests')}` : `${number} ${t('switch.portal.enquiry.guest')}`
    }

    return (
        <>
            <Card variant='outlined' sx={{ width: '100%', p: 2 }}>
                <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                    <Grid item xs={12}>
                        <TextField variant='h5' label={t('switch.portal.booking.views.summary.desc')} source="publicEventDescription" />
                    </Grid>
                    <Grid item xs={6}>
                        {showGuestNumber(record?.numberOfGuests)}
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceField
                            label="Venue"
                            source="venueId"
                            reference="venue"
                            link={false}
                            queryOptions={{ staleTime: 5 * 60 * 1000 }}
                        >
                            <TextField source="name" />
                        </ReferenceField>
                    </Grid>
                    <Grid item xs={6}>
                        {t('switch.portal.booking.views.summary.start_date')} {FormatDate(record?.bookingDate)}
                    </Grid>
                    <Grid item xs={6}>
                        {t('switch.portal.booking.views.summary.end_date')} {FormatDate(record?.bookingEndDate)}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography> {t('switch.portal.booking.views.summary.repeatrule')}: {ruleText}</Typography>
                    </Grid>
                </Grid>                
            </Card>
        </>
    )
}