import * as React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import { usePortal } from './usePortal'
import {
    useRecordContext,
    useDataProvider,
    useRedirect,
    useNotify
} from "react-admin";

export const useBookingPayment = () => {
    const context = useBookingPaymentContext()

    return context
}

export const BookingPaymentContext = createContext(undefined)

export const BookingPaymentContextProvider = ({ context, children }) => {
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const redirect = useRedirect()
    const notify = useNotify()

    const [isOpen, SetIsOpen] = useState(false)
    const [isUnpaid, setIsUnapid] = useState(false)
    const [isLoading, setIsLoading] = useState()
    const [outstandingBalance, setOutstandingBalance] = useState()
    const [overDueAmount, setOverDueAmount] = useState()
    const [nextPaymentAmount, setNextPaymentAmount] = useState()
    const [availableOptions, setAvailableOptions] = useState([])

    const PAY_OPTIONS = {
        OUTSTANDING_BALANCE: "1",
        NEXT_INSTALLMENT: "2",
        OVERDUE: "3",
    }

    const paymentChoices = [
        { label: 'Outstanding Balance', value: PAY_OPTIONS.OUTSTANDING_BALANCE },
        { label: 'Next Installment', value: PAY_OPTIONS.NEXT_INSTALLMENT },
        { label: 'Over Due', value: PAY_OPTIONS.OVERDUE },
    ];

    const [selectedOption, setSelectedOption] = useState(PAY_OPTIONS.OUTSTANDING_BALANCE);
    const [selectedAmount, setSelectedAmount] = useState(outstandingBalance)


    const { settings } = usePortal()

    const [showCardScreen, SetShowCardScreen] = useState(false)


    const [paymentRecord, setPaymentRecord] = useState()

    function CloseDialog() {
        SetIsOpen(false)
    }

    function OpenDialog() {
        SetIsOpen(true)
    }

    function GetOutStandingPayments() {
        return record?.paymentSchedule.filter((payment) => { return payment.paymentStatus === 'awaitingPayment' })
    }

    function GetOverduePayments() {
        const payRecords = GetOutStandingPayments()

        return payRecords.filter((payment) => {
            return new Date(payment.dueDate) <= new Date() && payment.paymentStatus === 'awaitingPayment'
        })
    }

    function GetNextPayment() {
        const payRecords = GetOutStandingPayments()
        const sorted = payRecords.sort((a, b) => {
            return new Date(a.dueDate) - new Date(b.dueDate)
        })
        return sorted[0]
    }

    function GetNextPaymentAmount() {
        var np = GetNextPayment()
        return np?.amount;
    }

    function CalculateOverDueAmount() {
        var payRecord = GetOverduePayments()
        let overDueAmount = payRecord.reduce((acc, payment) => {
            return acc + payment.amount
        }, 0)
        return overDueAmount;
    }

    function GetAvailableOptions(outstandingBalance, overDueAmount, nextAmount) {
        let options = []
        if (outstandingBalance === nextAmount) return options;
        options.push(paymentChoices[0]); // Oustanding Balance
        options.push(paymentChoices[1]); // Next installment
        if (overDueAmount > 0) options.push(paymentChoices[2]); // overdue 
        return options
    }

    function SelectPaymentOption(value) {

        switch (value) {
            case PAY_OPTIONS.NEXT_INSTALLMENT:
                setSelectedOption(PAY_OPTIONS.NEXT_INSTALLMENT)
                setSelectedAmount(nextPaymentAmount)
                setPaymentRecord(GetNextPayment())
                break;
            case PAY_OPTIONS.OUTSTANDING_BALANCE:
                setSelectedOption(PAY_OPTIONS.OUTSTANDING_BALANCE)
                setSelectedAmount(outstandingBalance)
                setPaymentRecord(FindPaymentRecord(GetOutStandingPayments(), outstandingBalance))
                break;
            case PAY_OPTIONS.OVERDUE:
                setSelectedOption(PAY_OPTIONS.OVERDUE)
                setSelectedAmount(overDueAmount)
                setPaymentRecord(FindPaymentRecord(GetOutStandingPayments(), overDueAmount))
                break;

        }

    }

    function InitaliseValues() {
        if (!record) return
        var _nextPaymentAmount = GetNextPaymentAmount()
        var _overDueAmount = CalculateOverDueAmount()
        var _outStandingBalance = record?.outstandingBalance;

        setOutstandingBalance(_outStandingBalance);
        setOverDueAmount(_overDueAmount)
        setNextPaymentAmount(_nextPaymentAmount)
        setIsUnapid(_outStandingBalance > 0)
        setAvailableOptions(GetAvailableOptions(_outStandingBalance, _overDueAmount, _nextPaymentAmount))
        setSelectedOption(PAY_OPTIONS.OUTSTANDING_BALANCE)
        setSelectedAmount(_outStandingBalance)
        setPaymentRecord(FindPaymentRecord(GetOutStandingPayments(), _outStandingBalance))
    }

    useEffect(() => {
        InitaliseValues()
    }, [record])



    const SetupPayment = () => {
        setIsLoading(true)
        const paymentRecord = FindPaymentRecord(GetOutStandingPayments(), selectedAmount)
        if (paymentRecord) {
            setPaymentRecord(paymentRecord)
            redirect('show', 'payments', paymentRecord.id, {}, { dialog: { dialogOpen: true } })
        }
        else {
            dataProvider.setupPayment(record, settings?.savedCards, selectedAmount).then((data) => {
                const paymentRecord = FindPaymentRecord(data.paymentSchedule, selectedAmount)
                if (paymentRecord) {
                    setPaymentRecord(paymentRecord)
                    setIsLoading(false)
                    SetShowCardScreen(true)
                    redirect('show', 'payments', paymentRecord.id, {}, { dialog: { dialogOpen: true } })

                }
                else {
                    setIsLoading(false)
                    notify("Unable to setup the payment information, please contact the venue", { type: "warning" })
                }
            })
        }
    }

    const FindPaymentRecord = (paymentSchedule, amount) => {
        if (!paymentSchedule) return
        let filteredArray = paymentSchedule.filter((payment) => {
            return amount === payment.amount && payment.paymentStatus === 'awaitingPayment'
        })

        filteredArray.sort((a, b) => {
            return new Date(a.dueDate) - new Date(b.dueDate)
        })
        return filteredArray[0]
    }




    let contextData = {
        availableOptions,
        isLoading,
        isOpen,
        isUnpaid,
        nextPaymentAmount,
        outstandingBalance,
        overDueAmount,
        paymentRecord,
        selectedAmount,
        selectedOption,
        CloseDialog,
        OpenDialog,
        SetupPayment,
        CalculateOverDueAmount,
        FindPaymentRecord,
        GetAvailableOptions,
        GetNextPayment,
        GetNextPaymentAmount,
        GetOutStandingPayments,
        GetOverduePayments,
        SelectPaymentOption,
        PAY_OPTIONS,
        showCardScreen,
        ...context,
    }

    return (
        < BookingPaymentContext.Provider value={contextData} >
            {children}
        </BookingPaymentContext.Provider >
    )
}

export const useBookingPaymentContext = () => useContext(BookingPaymentContext)