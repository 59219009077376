import React from 'react';
import {
    SimpleForm,
    ReferenceInput,
    required,
    SelectInput, useTranslate
} from 'react-admin';
import { Button, Typography, DialogContent, DialogActions } from '@mui/material';
import { useBookingCancel } from '../../Contexts/useBookingCancel';

export const CancellationReason = () => {
    const { SetCancellationReason, CloseCancelDialog } = useBookingCancel();
    const translate = useTranslate();


    return (
        <>
            <DialogContent dividers>
                <Typography gutterBottom>
                    {translate('switch.portal.booking.cancel.selectreason')}
                </Typography>
                <SimpleForm toolbar={null}>
                    <ReferenceInput
                        label="Reason"
                        source={"cancelReasonId"}
                        reference="refundreason"
                        sort={{ field: "name", order: "ASC" }}
                        perPage={100}
                        allowEmpty={true}
                    >
                        <SelectInput onChange={(e) => SetCancellationReason(e.target.value)} optionText="name" optionValue="id" validate={[required()]} filterToQuery={searchText => ({
                            name: searchText,
                        })} />
                    </ReferenceInput>
                </SimpleForm>
            </DialogContent>
            <DialogActions>
                <Button onClick={CloseCancelDialog}>
                    {translate('switch.portal.booking.cancel.back')}
                </Button>
            </DialogActions>
        </>
    );
};
