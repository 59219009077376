import React, { } from "react";
import {
    useTranslate,
    InfiniteListBase,
    WithListContext,
    InfinitePagination,
} from "react-admin";
import {
    Card,
    Typography,
    Container,
    Grid,
    Paper,
} from "@mui/material";
import FormatDate from "../../../components/FormatDate";
import CircularProgress from '@mui/material/CircularProgress';
import { EditCardDialog } from "../EditDialogs/EditCardDialog";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { GenericMobileListViews } from "../../Shared/GenericMobileListViews";
import { useMyAccount } from "../../Contexts/useMyAccount";
import { useTheme } from '@mui/material/styles';
import ContactlessIcon from '@mui/icons-material/Contactless';

export const ShowCardsMobile = ({ setCardCount }) => {
    const translate = useTranslate();
    const { getRandomHexColor, Open, signature } = useMyAccount();
    const theme = useTheme();
    return (
        <InfiniteListBase resource="Card" sort={{ field: 'expiry', order: 'DESC' }}>
            <Container>
                <WithListContext
                    render={({ isLoading, data, total }) => {
                        if (isLoading) {
                            return <CircularProgress />;
                        }
                        if (!isLoading) {
                            setCardCount(total);
                        }
                        return (
                            <GenericMobileListViews>
                                {data.map((item) => (
                                    <Card
                                            key={item?.id}
                                            variant="outlined"
                                            sx={{
                                                marginY: 1,
                                                width: "100%",
                                                marginX: "auto",
                                                height: "auto",
                                                minHeight:180,
                                                borderWidth: 2,
                                                borderColor: "#000",
                                                cursor: "pointer",
                                                backgroundColor: getRandomHexColor(item.brand),
                                            }}
                                            onClick={()=>Open(item)}
                                           >
                                        <Paper
                                            elevation={0}
                                            square
                                            sx={{
                                                background: "#000",
                                                height: 30,
                                                width: "100%",
                                                mt:1.5

                                            }} />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-evenly"
                                            alignItems="center"
                                        >
                                            <Typography sx={{ mt: 1, mr:1, ml:0.5, textShadow: '0 0 4px black', fontSize: '1.6rem', color: theme.palette.getContrastText(getRandomHexColor(item.brand)) }} variant={"body1"}>****</Typography>
                                            <Typography sx={{ mt: 1, mr: 1, textShadow: '0 0 4px black', fontSize: '1.6rem', color: theme.palette.getContrastText(getRandomHexColor(item.brand)) }} variant={"body1"}>****</Typography>
                                            <Typography sx={{ mt: 1, mr: 1, textShadow: '0 0 4px black', fontSize: '1.6rem', color: theme.palette.getContrastText(getRandomHexColor(item.brand)) }} variant={"body1"}>****</Typography>
                                            <Typography sx={{ textShadow: '0 0 4px black', fontSize: '1rem', mr: 1, color: theme.palette.getContrastText(getRandomHexColor(item.brand)) }} variant={"body1"}>{item.last4}</Typography>
                                            <img key={item?.id} src={`./image/cards/${item?.brand}.png`} alt={item.brand} width="55px" align="bottom" style={{ padding: "0px 10px", marginRight: "1px" }} />
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                        >
                                            <Typography sx={{ ml: 0.5, textShadow: '0 0 4px black', color: theme.palette.getContrastText(getRandomHexColor(item.brand)), fontSize: 14 }} variant={"body2"} >{translate('switch.portal.myaccount.cards.general.expiry')}  {FormatDate(item.expiry)}</Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                        >
                                        <Typography sx={{ ml: 0.5, textShadow: '0 0 4px black', color: theme.palette.getContrastText(getRandomHexColor(item.brand)), fontSize: 14 }} variant={"body2"}>{translate('switch.portal.myaccount.cards.general.consent')} </Typography>
                                        {item.consentToUse ? (
                                            <CheckIcon sx={{ fontSize: 18, color: "green" }} />
                                        ) : (
                                            <CloseIcon sx={{ fontSize: 18, color: "red" }} />
                                            )}
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                        >
                                        {item.default && (<>
                                            <Typography sx={{ ml: 0.5, textShadow: '0 0 4px black', color: theme.palette.getContrastText(getRandomHexColor(item.brand)), fontSize: 14 }} variant={"body2"}>{translate('switch.portal.myaccount.cards.general.default_card')} </Typography>
                                            <CheckIcon sx={{ fontSize: 18, color: "green" }} />
                                        </>
                                            )}
                                        </Grid>
                                    </Card>
                                ))}
                            </GenericMobileListViews>
                        );
                    }}
                />
            </Container >
            <InfinitePagination />
            <EditCardDialog />

        </InfiniteListBase >
    );
};
