import React  from "react";
import { useTranslate} from 'react-admin';
import Button from '@mui/material/Button';
import { useBookingPayment } from "../Contexts/useBookingPayment";


export const NextButton = () => {
    const translate = useTranslate()

    const {
        SetupPayment
    } = useBookingPayment();

    return (<>
        <Button onClick={SetupPayment} primary >{translate('switch.portal.paymentslist.next')}</Button>
    </>);
}