import React, { } from "react";
import { Datagrid, DateField, NumberField, ReferenceField, TextField } from 'react-admin';
import FormattedTime from "../../Shared/FormattedTime";


export const DesktopListView = () => {

    return (
        <Datagrid rowClick="show">
            <TextField source="publicEventDescription" label='switch.portal.enquiry.description' />
            <TextField source="switchURN" label='switch.portal.enquiry.urn' />
            <ReferenceField
                label='switch.portal.enquiry.venue'
                source="venueId"
                reference="venue"
                link={false}
                queryOptions={{ staleTime: 5 * 60 * 1000 }}
            >
                <TextField source="name" />
            </ReferenceField>
            <DateField label='switch.portal.enquiry.startdate' source="bookingDate" locales="en-GB" />
            <DateField label='switch.portal.enquiry.enddate' source="bookingEndDate" locales="en-GB" />
            <FormattedTime source="startTime" label='switch.portal.enquiry.starttime' />
            <FormattedTime source="endTime" label='switch.portal.enquiry.endtime' />
            <NumberField source="budget" label='switch.portal.enquiry.budget' options={{
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2
            }} />
        </Datagrid>
    )
}